import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import HotMovie from '../components/Banners/HotMovie/HotMovie'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import CategoryContainer from '../components/CategoryContainer/CategoryContainer'
import MovieGrid from '../components/MovieGrid/MovieGrid'
import { calculateCount } from '../helpers'
import useWindowSize from '../helpers/useWindowSize'
import i18n from '../i18n'
import { fetchGenres } from '../store/actions/genres.action'
import { fetchMovies } from '../store/actions/movies.action'
import { Country, SelectedFilters, YearsFilter } from '../store/models/common'
import { AppState } from '../store/reducers'
import { fetchGet } from '../store/rest/fetch'


interface RouteParams {
	selectedGenre: string
	selectedCountry: string
}

const applyQS = (query: SelectedFilters, per_page: number = 20) => {
	let queryString = ''
	for (let [key, value] of Object.entries(query)) {
		if (key === 'genres' || key === 'countries') {
			value.map((i: any) => {
				queryString += `&${key}[]=${i.id}`
			})
		} else {
			if (value.min) queryString += `&min=${value.min}`
			if (value.max) queryString += `&max=${value.max}`
		}
	}
	queryString += `&per_page=${per_page}&page=${query.page}`
	return queryString
}

const Movies: React.FC = ({}) => {
	const { t } = useTranslation(['azcinemaonline'])
	const dispatch = useDispatch()
	const history = useHistory<{ year: number }>()
	const { selectedGenre, selectedCountry } = useParams<RouteParams>()
	const movies = useSelector((state: AppState) => state.movies.movies)
	const genres = useSelector((state: AppState) => state.genres.genres)
	const [availableCountries, setAvailableCountries] = useState<Country[]>([])
	const [availableYears, setAvailableYears] = useState<YearsFilter>({ min: 1970, max: 2030 })
	const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
		genres: [],
		countries: [],
		years: {
			min: null,
			max: null,
		},
		page: 1,
	})
	const size = useWindowSize()
	const per_page = calculateCount(size.width)
	
	useEffect(() => {
		if (movies.length < per_page * selectedFilters.page + 1) {
			dispatch(fetchMovies(applyQS(selectedFilters, selectedFilters.page * per_page), 0, per_page))
		}
	}, [size.width])

	useEffect(() => {
		let page = +(
			history.location.search
				.split('?')
				.find((el) => el.includes('page'))
				?.slice(5) || 1
		)
		setNewFilters(page, 'page')
		if (movies.length < per_page * page) {
			dispatch(fetchMovies(applyQS(selectedFilters, page * per_page), 0, per_page))
		}
		fetchGet('filters/countries')
			.then((r) => {
				if (r.status == 'ok') setAvailableCountries(r.data)
			})
			.catch((err) => console.log(err))
		fetchGet('filters/years')
			.then((r) => {
				if (r.status == 'ok') setAvailableYears(r.data)
			})
			.catch((err) => console.log(err))
		const yearFromState = history.location?.state?.year
		if (yearFromState) setSelectedFilters({ ...selectedFilters, years: { min: yearFromState, max: yearFromState } })
	}, [])

	const init = useRef(true)
	useEffect(() => {
		if (genres.length > 0 && availableCountries.length > 0 && init.current) {
			init.current = false
			const findedGenre = genres.find((i) => {
				return i.slug === selectedGenre
			})
			if (findedGenre) {
				setNewFilters(findedGenre, 'genres')
				const findedCountry = availableCountries.find((i) => {
					return i.name === selectedCountry
				})
				findedCountry && setNewFilters(findedCountry, 'countries')
			} else {
				const findedCountry = availableCountries.find((i) => {
					return i.name === selectedGenre
				})
				findedCountry && setNewFilters(findedCountry, 'countries')
			}
		}
	}, [genres, availableCountries])
	/* checked */
	useEffect(() => {
		if (genres.length > 0 && availableCountries.length > 0) {
			let newUrl = '/' + t('routes.movies')
			if (selectedFilters.genres.length === 1) newUrl += '/' + selectedFilters.genres[0].slug
			if (selectedFilters.countries.length === 1) newUrl += '/' + selectedFilters.countries[0].name
			if (selectedFilters.page > 1) newUrl += '?page=' + selectedFilters.page
			history.replace(newUrl)
		}
	}, [selectedFilters])
	const setNewFilters = (filter: any, type: 'countries' | 'genres' | 'years' | 'page') => {
		switch (type) {
			case 'genres': {
				const firstLength = selectedFilters[type].length
				const newFilters = selectedFilters[type].filter((i) => {
					return i.id !== filter.id
				})
				const afterLength = newFilters.length
				if (afterLength === firstLength) {
					setSelectedFilters((prevState) => {
						return { ...prevState, [type]: [...prevState[type], filter], page: 1 }
					})
				} else
					setSelectedFilters((prevState) => {
						return { ...prevState, [type]: newFilters, page: 1 }
					})
				break
			}
			case 'countries': {
				const firstLength = selectedFilters[type].length
				const newFilters = selectedFilters[type].filter((i) => {
					return i.id !== filter.id
				})
				const afterLength = newFilters.length
				if (afterLength === firstLength) {
					setSelectedFilters((prevState) => {
						return { ...prevState, [type]: [...prevState[type], filter], page: 1 }
					})
				} else
					setSelectedFilters((prevState) => {
						return { ...prevState, [type]: newFilters, page: 1 }
					})
				break
			}
			case 'years': {
				setSelectedFilters((prevState) => {
					return { ...prevState, [type]: filter, page: 1 }
				})
				break
			}
			case 'page': {
				setSelectedFilters((prevState) => {
					return { ...prevState, [type]: filter }
				})
				break
			}
			default:
				break
		}
	}
	const resetFilter = (type: 'countries' | 'genres') => {
		setSelectedFilters((prevState) => {
			return { ...prevState, [type]: [] }
		})
	}

	useEffect(() => {
		dispatch(fetchMovies(applyQS(selectedFilters, per_page), selectedFilters.page, per_page))
	}, [selectedFilters])
	const lang = i18n.language
	const title = lang === 'en' ? "Movies" : lang==='ru' ? "Фильмы" : "Filmlər" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	const hotMover = {
		title: (lang === 'en') ? "The Precinct" : ( lang==='ru' ) ? "Участок" : "Sahə",
		cover: 'https://picsum.photos/1000/200',
	}
	return (
		<React.Fragment>
			<Helmet title={title} />
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>

			{/* <PageInfoHeader text="Movies" bgImg='https://picsum.photos/1600/500' /> */}

			<MovieGrid
				movies={movies}
				genres={genres}
				countries={availableCountries}
				years={availableYears}
				selectedFilters={selectedFilters}
				setSelectedFilters={setNewFilters}
				resetFilter={resetFilter}
			/>

			<HotMovie {...hotMover} />

			{genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 22
						})[0]
					}
				/>
			)}

			{/* <MediumMovieContainer />
            <SponsorsContainer /> */}
		</React.Fragment>
	)
}
export default Movies
