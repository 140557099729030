import React from 'react'
import { Col, Row } from 'reactstrap'
import { Category, Post } from '../../store/models/common'
import BlogFilterContainer from '../BlogFilterContainer/BlogFilterContainer'
import FollowUs from '../FollowUs/FollowUs'
import SubscriptionStatus from '../Subscription/Subscription'
import './BlogPageContainer.scss'
import { BlogPageContainerCard } from './BlogPageContainerCard'

interface BlogPageContainerProps {
	posts: Post[]
	categories: Category[]
}

const BlogPageContainer: React.FC<BlogPageContainerProps> = ({ posts, categories }) => {
	return (
		<div className="BlogPageContainer container-fluid">
			<Row className="rowPart">
				<Col className="blogGrid" xs={12} md={9}>
					<Row style={{ width: '100%' }}>
						{posts.map((post, index) => {
							if (post.title) return <BlogPageContainerCard key={index} post={post} />
						})}
					</Row>
				</Col>
				<Col xs={{ size: 12 }} md={{ size: 3 }} style={{ marginTop: '2rem' }}>
					<SubscriptionStatus />
					<BlogFilterContainer categories={categories} />
					<FollowUs />
				</Col>
			</Row>
		</div>
	)
}

export default BlogPageContainer
