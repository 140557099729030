import React from 'react'
import '../SerialDetail.scss'

interface SerialTrailerProps {

}

const SerialTrailer: React.FC<SerialTrailerProps> = ({}) => {
  return (
    <div className='SerialTrailer pr-3'>
        <div className="" /*style={{ display: !playerUrl ? 'none' : 'block' }}*/ >
            {/* <ReactJWPlayer
                playerId={'123'}
                playerScript={config.player_url}
                file={playerUrl}
                onReady={onReady}
                onEnterFullScreen={onEnterFullScreen}
                onExitFullScreen={onExitFullScreen}
            /> */}
		</div>
        <div className="trailer-player" style={{height: "500px"}}>
            
        </div>
    </div>
  )
}

export default SerialTrailer
