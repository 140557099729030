import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ExpansionComponent from '../components/ExpansionComponent/ExpansionComponent'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useHistory, useParams } from 'react-router'

interface RouteParams {
	id: string
}

const Expire: React.FC = ({}) => {
	const lang = i18n.language
    const { t } = useTranslation('azcinemaonline')



    


	return (
		<>
			<Helmet title="Error" />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<ExpansionComponent/>
			<SponsorsContainer />
		</>
	)
}

export default Expire






