import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Container, Row } from 'reactstrap'
import i18n from '../../../../i18n'
import { Movie } from '../../../../store/models/movies'
import { AppState } from '../../../../store/reducers'
import { fetchGet } from '../../../../store/rest/fetch'
import MovieListGrid from '../../../MovieListGrid/MovieListGrid'
import SponsorsContainer from '../../../SponsorsContainer/SponsorsContainer'

interface ViewsHistoryProps { }

export const ViewsHistory: React.FC<ViewsHistoryProps> = ({ }) => {
	const lang = i18n.language
	const title = lang === 'en' ? "View history" : lang === 'ru' ? "История просмотров" : "Baxış tarixçəsi"
	const [viewsHistoryMovies, setViewsHistoryMovies] = useState<Movie[]>([])
	const [isFetching, setIsFetching] = useState(false)
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	const router = useHistory()
	const { t } = useTranslation('azcinemaonline')
	useEffect(() => {
		setIsFetching(true)
		if (!isAuthenticated) setIsFetching(false)
		else
			fetchGet('profile/viewslist')
				.then((res) => {
					if (res.status === 'ok') {
						setViewsHistoryMovies(res.data)
						setIsFetching(false)
					}
				})
				.catch((err) => {
					console.log(err)
					setIsFetching(false)
				})
	}, [])
	return (
		<>
			<Helmet title={title}/>
			<div className="account">
				<Container fluid={true}>
					<Row className="ViewsHistory">
						<div
							className="backToAccaunt"
							onClick={() => {
								router.push('/' + t('routes.account'))
							}}
							>
							<i className="fas fa-chevron-left"></i>
							{t('account.backButton')}
						</div>
					</Row>
					<h1 className="title">{t('account.viewsHistory')}</h1>
				</Container>
				<MovieListGrid movies={viewsHistoryMovies} />
				{viewsHistoryMovies.length === 0 && (
					<Container fluid={true} className="movies-list empty">
						<h2 className="empty text-center my-5">{t('account.emptyViewsHistory')}</h2>
					</Container>
				)}

				<SponsorsContainer />
			</div>
		</>
	)
}
