import React from 'react'
import { useTranslation } from 'react-i18next'
import './PrivacyContainer.scss'
interface PrivacyContainerProps {}

const PrivacyContainer: React.FC<PrivacyContainerProps> = ({}) => {
	const { t, i18n } = useTranslation(['privacy'])
	return (
		<div className="PrivacyContainer">
			<div className="container mt-5 privacy" dangerouslySetInnerHTML={{ __html: t('privacy') }}></div>
		</div>
	)
}

export default PrivacyContainer
