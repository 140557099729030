import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { Genre } from '../../../store/models/genres'
import { Serial } from '../../../store/models/serials'
import '../SerialDetail.scss'

interface Props {
	// serial: Serial
	// genres: Genre[]
}

const SerialHeader: React.FC<Props> = ({ /* serial, genres */ }) => {
	return (
		<div className="serial-header">
			<Row>
				<Col className="col-9">
					<h1 className="serial-title">
                        Serial Name
						{/* {serial.name} */}
						{/* <span className="original">({movie.name})</span> */}
					</h1>
				</Col>
				<Col className="col-3 text-right">
					<span className="age">
                        {/* {serial.ageLimit} */} 16
                        +
                    </span>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col md={8} xs={12}>
					<ul className="genre-list">
                    <li className="genre-item">
                        <Link to={'/serials/'}>Genre</Link>
					</li>
						{/* {genres.map((genre, index) => {
							return (
								<li className="genre-item" key={index}>
									<Link to={'/serials/' + genre.slug}>{genre.name}</Link>
								</li>
							)
						})} */}
					</ul>
				</Col>
			</Row>
		</div>
	)
}

export default SerialHeader
