import React, { useEffect } from 'react';
import './LoadingComponent.scss';
import { ReactComponent as Logo } from '../../assets/logo.svg'

interface LoadingComponentProps {

}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ }) => {

    useEffect(() => {
        document.documentElement.classList.add("nonScroll");
        return () => {
            document.documentElement.classList.remove("nonScroll");
        }
    }, )


    return (
        <div className='LoadingComponent'>
            <Logo className='logo' />
        </div>
    )
}

export default LoadingComponent;