import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import AboutPerson from './AboutPerson/AboutPerson'
import TrailerComponent from './TrailerComponent/TrailerComponent'
import './CrowdfundingDetailComponent.scss'
import mainImg from '../../assets/photo.jpg'
import { Fundings } from '../../store/models/fundings'
import { fetchGet, fetchPostPayment, fetchPostText } from '../../store/rest/fetch'
import { toast } from 'react-toastify'
import { getProfile } from '../../store/actions/profile.actions'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../store/reducers'
import { SETTINGS } from '../../store/types'
import { CloseButton } from '../CloseButton/CloseButton'
import { useHistory } from 'react-router'
import i18n from '../../i18n'
import { useProgressiveImage } from '../../helpers/useProgressiveImage'
import DontanorsComponent from './DonatorsComponent/DonatorsComponent'
import { Donators } from '../../store/models/donators'



interface CrowdfundingDetailComponentProps {
    funding: Fundings | null
}

const CrowdfundingDetailComponent: React.FC<CrowdfundingDetailComponentProps> = ({funding}) => {
	const { t } = useTranslation(['azcinemaonline'])
    const lang = i18n.language
    const [ projectId, setProjectId ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ money, setMoney ] = useState('')
    const [ name, setName ] = useState('')
    const [ dispName, setDispName ] = useState('')
    const [ anonymName, setAnonymName ] = useState(false)
    const [ note, setNote ] = useState('')
    const [ open, setOpen ] = useState(false)
    // const [ leftDay, setLeftDay ] = useState(0)
    const [ percent, setPercent ] = useState(0)
    const [ total, setTotal ] = useState('')
    const [ isFetching, setIsFetching ] = useState(false)
    const dispatch = useDispatch()
    // useEffect(() => {
    //     if (open) document.documentElement.classList.toggle("nonScroll");
    //     else document.documentElement.classList.toggle("nonScroll");
    //   }, [open]);
    const router = useHistory()

    useEffect(()=> {
        if(funding /*&& funding.created_at && funding.end*/) {
            // var end = new Date(funding?.created_at).getTime() / 1000
            // var today = new Date().getTime() / 1000
            // setLeftDay(-(Math.floor((end - today) / 86400)))
            setProjectId(`${funding.id}`)
            setTotal(`${funding.total}`)
        }

        if(funding && funding.total && funding.budget) {
            setPercent((funding.total / funding.budget) * 100)
        }
    })


    const donate = (project_id: number) => {
		var windowReference = window.open()
		setIsFetching(true)
		fetchPostText(
			{
                email: email,
                name: name,
                donate: money,
                project_id: project_id,
                description: note,
                anonim: anonymName,
                display_name: dispName
			},
			'donate'
		)
			.then((res) => {
				let trans_id_index = res.indexOf('trans_id=')
				let trans_id = res.slice(trans_id_index + 9)
				localStorage.setItem('payment/pasha', JSON.stringify({ trans_id, url: router.location.pathname }))

				if (windowReference) {
					windowReference.location.href = res
					getTransStatus(trans_id, 0)
				}
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
				windowReference?.close()
			})
	}

    const getTransStatus = (trans_id: string, iteration: number) => {
		fetchPostPayment(
			{
				trans_id,
				is_client: true,
			},
			'payment/pasha'
		)
			.then((res) => {
				if (res.status === 'PENDING' && iteration < 180) {
					setTimeout(() => {
						getTransStatus(trans_id, iteration + 1)
					}, 5000)
				} else if (res.status === 'SUCCESS') {
					setIsFetching(false)
					toast.success(res.data.ErrorMessage, { closeButton: CloseButton })
					dispatch(getProfile)
				} else if (res.status === 'FAILED') {
					setIsFetching(false)
					toast.error(res.data.ErrorMessage, { closeButton: CloseButton })
					dispatch(getProfile)
				}
			})
			.catch((err) => {
				console.log(err)
				if (iteration < 180) {
					setTimeout(() => {
						getTransStatus(trans_id, iteration + 1)
					}, 5000)
				}
			})
	}


    const [donators, setDonators] = useState<Donators | null>(null)

    useEffect(()=> {
        if(funding) {
            getDonators();
        }
    },[projectId])

    const getDonators = () => {
            fetchGet('donates/project/' + projectId)
            .then((response) => {
                if(response.status === 'ok' ) {
                    setDonators(response.data);
                }
                console.log(response.data);
                
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const monthNamesEN = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthNamesRU = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь ", "Октябрь", "Ноябрь", "Декабрь"];
    const monthNamesAZ = ["Yanvar", "Fevral", "Mart", "Aprel", "May", "İyun", "İyul", "Avqust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"];
    const posterCover = useProgressiveImage(encodeURI(funding?.cover || ''))
    const produser1 = useProgressiveImage(encodeURI(funding?.producer_photo || ''))
    const producer2 = useProgressiveImage(encodeURI(funding?.producer2_photo || ''))

    
    

	return (
        <Container fluid className="CrowdfundingDetailComponent d-flex flex-wrap">
            {funding ?
            

            <div className='CrowdfundingDetailRow w-100 d-flex flex-wrap column-reverse'>
                <Col xs='12' md='8' className='d-flex flex-wrap' style={{height: "fit-content"}}>

                    <div className="filmTitle w-100">
                        {funding.title}
                    </div>

                    <div className="mainImgContainer w-100">
                        {/* {funding.cover ? 
                            <img src={posterCover} alt="" className="mainImg w-100" />
                        :
                            <img src={mainImg} alt="" className="mainImg w-100" />
                        } */}

                        {
                            funding.trailer ?
                                <TrailerComponent src={funding.trailer} cover={funding.cover} />
                            :

                                <img src={posterCover} alt="" className="mainImg w-100" />  
                        }
                    </div>

                    <div className="filmContent d-flex flex-wrap w-100">
                        {funding.description ? 
                        <p>{funding.description}</p>
                        : 
                        <p>{funding.short}</p>
                        }
                    </div>

                    {funding.producer_title ? 
                    <AboutPerson title={funding.producer_title} cover={produser1} desc={funding.producer_description} subtitle={funding.producer_subtitle}/>
                    : 
                    ''}
                    {funding.producer2_title ? 
                    <AboutPerson title={funding.producer2_title} cover={producer2} desc={funding.producer2_description} subtitle={funding.producer2_subtitle}/>
                    : 
                    ''}
                    
                </Col>
                <Col xs='12' md='4' className='p-2' style={{height: "fit-content"}} id="sticky-sidebar">
                    <Col xs='12' className="infoBannerContainer d-flex flex-wrap">
                        <Col xs='12' className="d-flex justify-content-between align-items-center">
                            <div className="filmTitleContainer">{funding.title}</div>
                            <div className="projectCategory film">{funding.type}</div>
                        </Col>
                        <Col xs='12' className="filmTinyDescription">
                            {funding.short}
                        </Col>

                        <Col xs='12' className='aboutMoneySection mt-4'>
                            <div className="gatheredMoney w-100">
                                <span> {parseInt(total)} </span> ₼
                            </div>

                            <div className="progressContainer">

                                <div className="statisticSection w-100 d-flex justify-content-between">
                                    <div className="outOf">{t("crowdfunding.outof")} {funding.budget}₼</div>
                                    <div className="gatheredPer">{t("crowdfunding.gathered")} {percent.toFixed(2)}%</div>
                                </div>
                                <Progress className='w-100 mb-3' multi>
                                    <Progress bar value={percent} />
                                </Progress>
                                <div className="numberStaticContainer my-5 d-flex justify-content-between">
                                    <div className="supportCountContainer d-flex flex-column">
                                        <div className="supportCountNumber">{funding.fundings} {t("crowdfunding.times")}</div>
                                        <div className="supportCountTitle">{t("crowdfunding.supported")}</div>
                                    </div>
                                    {/* <div className="daysLeftContianer d-flex flex-column">
                                        <div className="daysLeftNumber">{leftDay} {t("crowdfunding.days")}</div>
                                        <div className="daysLeftTitle" style={{textAlign: "center"}}>{t("crowdfunding.left")}</div>
                                    </div> */}
                                    <div className="beginningDateContainer d-flex flex-column">
                                        <div className="beginningDateNumber">
                                            {funding.start.split('-')[2].split(' ')[0]} <span></span>
                                            {lang == 'en' ? monthNamesEN[(new Date(funding.start)).getMonth()] : 
                                             lang=='ru' ? monthNamesRU[(new Date(funding.start)).getMonth()] : 
                                             lang=='az' ? monthNamesAZ[(new Date(funding.start)).getMonth()] : 
                                            ''}
                                        </div>
                                        <div className="beginningDateTitle" style={{textAlign: "end"}}>{t("crowdfunding.begin")}</div>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="donatedPeople">
                                <div className="listTitle">
                                    Last Donates
                                </div>
                                <div className="listOfPeople">
                                    <div className="listRow w-100 d-flex justify-content-between">
                                        <div className="fullnameColumn">
                                            Full name
                                        </div>
                                        <div className="gatheredMoneyColumn">
                                            12 ₼
                                        </div>
                                    </div>
                                    <div className="listRow w-100 d-flex justify-content-between">
                                        <div className="fullnameColumn">
                                            Full name
                                        </div>
                                        <div className="gatheredMoneyColumn">
                                            12 ₼
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </Col>

                        {funding.document ? 
                            <Col xs='6'>
                                <a href={funding.document} download className='docsLink'>Full information</a>
                            </Col>
                        : ''}
                        

                        <Col xs='6'>
                            <Button
                                className="supportButton text-center p-2"
                                onClick={() => {setOpen(true)}}
                            >
                                {t("crowdfunding.support")}
                            </Button>
                        </Col>
                        
                        <Modal 
                            className='promoModal'
                            isOpen={open}
                        >
                            <div className="promoModal-dialog">
                                <div className="modal-content promoModal-content">
                                    <div className="modal-header promoModal-header">
                                        <h5 className="modal-title promoModal-title" id="promoModalLabel">{t("crowdfunding.donating")}</h5>
                                        <button type="button" className="close" onClick={() => setOpen(false)}>
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body promoModal-body">
                                        <form>
                                            <label> {t("crowdfunding.fullname")} </label>
                                            <input type="text" className="promoInput w-100 mb-3" onChange={(e)=>{setName(e.target.value)}}/>
                                            <label> {t("crowdfunding.dispName")} </label>
                                            <input disabled={anonymName ? true : false} type="text" className="promoInput w-100 mb-3" onChange={(e)=>{setDispName(e.target.value)}}/>
                                            <div className="d-flex w-100 mb-2">
                                                <input type="checkbox" className="mr-2 mt-1 " onChange={(e)=>{anonymName ? setAnonymName(false) : setAnonymName(true)}}/>
                                                <label style={{color: 'gray', fontSize:'0.9rem'}}> {t("crowdfunding.anonym")} </label>
                                            </div>
                                            <label> {t("crowdfunding.email")} </label>
                                            <input type="email" className="promoInput w-100 mb-3" onChange={(e)=>{setEmail(e.target.value)}}/>
                                            <label> {t("crowdfunding.money")} </label>
                                            <input type="number" min='0' className="promoInput w-100 mb-3" onChange={(e)=>{setMoney(e.target.value)}}/>
                                            <label> {t("crowdfunding.note")} </label>
                                            <textarea style={{minHeight: "60px"}} className="promoInput w-100 mb-3" onChange={(e)=>{setNote(e.target.value)}}/>
                                            
                                            <div onClick={() => {donate(funding.id); setOpen(false)}} className="promoBtn mt-3 p-3">{t("crowdfunding.donate")}</div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </Modal>
                    
                    </Col>

                    <Col xs='12' className="mt-5 p-0">
                        <div className="donateTitle">{t('crowdfunding.lastdonates')}</div>
                        {donators && donators.map((donator)=>{
                            return (

                                <DontanorsComponent 
                                    id={donator.id} 
                                    description={donator.description} 
                                    display_name={donator.display_name} 
                                    email={donator.email} 
                                    donate={donator.donate} 
                                    anonym={donator.anonym} 
                                />
                            
                                )
                        })
                        }
                    </Col>
                </Col>
            </div>
             : ''}
		</Container>
	)
}

export default CrowdfundingDetailComponent
