import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
import './CollectionsComponent.scss'

interface CollectionsComponentProps {}

let collections = [
	{
		title: 'Lorem, ipsum dolor sit amet. Who is Gadimlie?',
		cover: 'https://picsum.photos/600/1000',
	},

	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/601/1001',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/602/1002',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/604/1004',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/605/1005',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/606/1006',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/604/1007',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/605/1008',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/604/1009',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/605/1010',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/604/1011',
	},
	{
		title: 'Lorem, ipsum.',
		cover: 'https://picsum.photos/605/1012',
	},
]

const CollectionsComponent: React.FC<CollectionsComponentProps> = ({}) => {
	const { t } = useTranslation(['azcinemaonline'])
	return (
		<Container className=" CollectionsComponent">
			<Row className="rowPart">
				<Col className="collectionsGrid" xs={12}>
					<Row
						style={{
							textAlign: 'center',
							justifyContent: 'center',
							marginBottom: '1rem',
							fontSize: '2.5rem',
							fontWeight: 'bold',
						}}
					>
						{t('collections.collections')}
					</Row>
					<Row style={{ width: '100%' }}>
						{collections.map((post, index) => {
							return ''
						})}
					</Row>
				</Col>
			</Row>
		</Container>
	)
}

export default CollectionsComponent
