import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageInfoHeader } from '../components/PageInfoHeader/PageInfoHeader'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import BlogPageContainer from '../components/BlogPageContainer/BlogPageContainer'
import { useTranslation } from 'react-i18next'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import { fetchGet } from '../store/rest/fetch'
import { Category, Post } from '../store/models/common'
import { useParams } from 'react-router'
import i18n from '../i18n'

interface RouteParams {
	category: string
}
const Blog: React.FC = () => {
	const lang = i18n.language
	const { t } = useTranslation(['azcinemaonline'])
	
	const [posts, setPosts] = useState<Post[]>([])
	const { category } = useParams<RouteParams>()
	const [categories, setCategories] = useState<Category[]>([])
	const getData = () => {
		fetchGet('categories')
			.then((res) => {
				if (res.status == 'ok') {
					setCategories(res.data.data)
					const t = res.data.data.find((el: Category) => el.slug == category)
					let url: string = ''
					if (t) url = 'categories/' + t.id + '/posts'
					else url = 'posts'
					fetchGet(url)
						.then((res) => {
							res.status == 'ok' && setPosts(res.data.data)
						})
						.catch((err) => {
							console.log(err)
						})
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}
	useEffect(() => {
		getData()
	}, [])
	useEffect(() => {
		getData()
	}, [category])
	return (
		<>
			<Helmet title={t('breadcrumbs.blog')} />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<BreadCrumb title={t('breadcrumbs.blog')}>
				<BreadCrumbItem name={t('breadcrumbs.home')} to="/" />
				<BreadCrumbItem name={t('breadcrumbs.blog')} current={true} />
			</BreadCrumb>
			<BlogPageContainer posts={posts} categories={categories} />
			<SponsorsContainer />
		</>
	)
}
export default Blog
