import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col } from 'reactstrap'
import { FAQ } from '../../store/models/common'
import { fetchGet } from '../../store/rest/fetch'
import QuestionComponent from '../QuestionComponent/QuestionComponent'

import './QuestionsContainer.scss'

const FAQarray = [
	{
		question: 'How does Az Cinema work?',
		answer:
			'Just surf the site and find one of our films or programmes that out like. If you have never used Az Cinema before, we ask you to fill in a simple registration form so that you can have an Account. Then all you need to do is Rent the video and you can see it straightaway or comeback to it by logging in to your Account.',
	},
	{
		question: 'Why do I need an Account?',
		answer:
			'You create an Account so that there is always a place where you can go to see what films you have Rented and what you current Rental is. This way you can choice to watch part of a video at one time and then come back to it to watch the rest at a later date (as long as it is within seven days of the Rental). Equally, you may want to check out your previous Rentals so that you can choice to what one again another time.',
	},
	{
		question: 'Why Az Cinema?',
		answer:
			'We have created Az Cinema to promote Azerbaijani film and television worldwide. We are conscious that there are lots of Azerbaijani speakers in countries around the world who want to see and enjoy great content. Equally, we want to give people who may have never experienced Azerbaijani culture the change to see great film and television.',
	},
	{
		question: "What's the selection like?",
		answer:
			'We have fantastic Azerbaijani Feature Films, Short Films and Documentaries for you to watch and enjoy.',
	},
	{
		question: 'Can I see Az Cinema on my TV?',
		answer:
			'If you want, why not connect you PC or laptop to your TV so you can watch our great content on your TV.',
	},
	//{question: 'How much does Az Cinema Cost?' , answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has when an unknown printer took a galley of type and scrambled it to make.'},
	{
		question: 'How do I pay?',
		answer:
			'You can pay for each Rental by using either one of our 2 x payment processing partners, GoldenPay or Azericard',
	},
	{
		question: 'Can I really cancel at any time?',
		answer:
			'Don’t worry – there is no long term commitment from you. All you do is choice when you might want to Rent a video. We do not tie you into any contracts and hence there is no need to cancel. Your Account will stay live so you can come back and pick up where you were before.',
	},
]

interface QuestionsContainerProps {}

const QuestionsContainer: React.FC<QuestionsContainerProps> = ({}) => {
	const [collapsed, setCollapsed] = useState(-1)
	const { t, i18n } = useTranslation(['azcinemaonline'])
	let FAQarray: FAQ[] = [
		{ question: t('faq.faq1.q1'), answer: t('faq.faq1.a1') },
		{ question: t('faq.faq2.q2'), answer: t('faq.faq2.a2') },
		{ question: t('faq.faq3.q3'), answer: t('faq.faq3.a3') },
		{ question: t('faq.faq4.q4'), answer: t('faq.faq4.a4') },
		{ question: t('faq.faq5.q5'), answer: t('faq.faq5.a5') },
		{ question: t('faq.faq6.q6'), answer: t('faq.faq6.a6') },
	]
	useEffect(() => {
		fetchGet('faq')
			.then((res) => {
			})
			.catch((err) => console.log(err))
	}, [])
	const openClosed = (index: number): void => {
		collapsed === index ? setCollapsed(-1) : setCollapsed(index)
	}

	return (
		<Col xs={{ size: 12 }} md={{ size: 8 }} className="QuestionsContainer">
			 <h1 className="title mb-2">{t('faq.faq')}</h1>
			<div className="questions">
				{FAQarray.map((FAQ, index) => {
					return (
						<QuestionComponent
							key={index}
							collapsed={collapsed === index}
							index={index}
							collapse={openClosed}
							question={FAQ.question}
							answer={FAQ.answer}
						/>
					)
				})}
			</div>
		</Col>
	)
}

export default QuestionsContainer
