import React, { useState } from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import i18n from '../../../i18n'
import routes from '../../../routes'
import './LanguageSwitcher.scss'

const LanguageSwitcher: React.FC = () => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const toggle = () => setDropdownOpen((prevState) => !prevState)
	const lang = i18n.language
	const history = useHistory()
	const revealRoute = (language: 'en' | 'ru' | 'az') => {
		let currLanguage = i18n.language
		let currPath = history.location.pathname
		let locations = currPath.substr(1).split('/')
		locations.forEach((el, index) => {
			// @ts-ignore
			for (const [key, value] of Object.entries(routes[currLanguage])) {
				if (value === el) {
					locations[index] = key
				}
			}
		})
		let newLocation: string = ''
		locations.forEach((el) => {
			if (typeof routes[language][el] == 'string') {
				newLocation += '/'
				newLocation += routes[language][el]
			} else {
				newLocation += '/'
				newLocation += el
			}
		})
		return newLocation
	}
	return (
		<div className="LanguageSwitcher">
			<Dropdown isOpen={dropdownOpen} className="dropdown" toggle={toggle} style={{ height: '100%' }}>
				<DropdownToggle
					caret
					style={{ backgroundColor: 'transparent', border: 'transparent', fontWeight: 'bold' }}
				>
					{lang.toUpperCase()}
				</DropdownToggle>
				<DropdownMenu style={{ transition: '0.3s ease-in-out' }}>
					<DropdownItem
						onClick={(e) => {
							e.preventDefault()
							window.location.pathname = 'az' + revealRoute('az')
						}}
					>
						Azərbaycan
					</DropdownItem>
					<DropdownItem
						onClick={(e) => {
							e.preventDefault()
							window.location.pathname = 'en' + revealRoute('en')
						}}
					>
						English
					</DropdownItem>
					<DropdownItem
						onClick={(e) => {
							e.preventDefault()
							window.location.pathname = 'ru' + revealRoute('ru')
						}}
					>
						Русский
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

export default LanguageSwitcher
