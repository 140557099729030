import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ResetPassComponent from '../components/ResetPassComponent/ResetPassComponent'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'
import { useHistory, useParams } from 'react-router'
import { fetchGet, fetchPost } from '../store/rest/fetch'
import { toast } from 'react-toastify'
import { CloseButton } from '../components/CloseButton/CloseButton'

interface RouteParams {
	id: string
}

const ResetPass: React.FC = ({}) => {
	const lang = i18n.language
    const { t } = useTranslation('azcinemaonline')
	const title = lang === 'en' ? "Account" : lang==='ru' ? "Личный кабинет" : "Şəxsi kabinet" 
	const currentTitle = lang === 'en' ? "Password Reset" : lang==='ru' ? "Восстановление пароля" : "Şifrənin yenilənməsi" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
    const router = useHistory()
    const [resetToken, setResetToken] = useState(router.location.search.slice(7))
    const [currentEmail, setCurrentEmail] = useState('')
	const [isFetching, setIsFetching] = useState(false)
    useEffect(() => {
		getAllowToReset()
	}, [])

    const getAllowToReset = () => {
        setIsFetching(true)
        console.log(resetToken);
        fetchPost( [], `reset-password?token=${resetToken}`)
            .then((response) => {
                setCurrentEmail(response.data.email)
                console.log(currentEmail);
            })
            .catch((err) => {
                router.push('/expire') 
                toast.error(err, { closeButton: CloseButton });
				console.log(err)
			})
    }



    


	return (
		<>
			<Helmet title={title} />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={currentTitle} current={true} />
			</BreadCrumb>
			<ResetPassComponent email={currentEmail} token={resetToken}/>
			<SponsorsContainer />
		</>
	)
}

export default ResetPass
