import React, { useState } from 'react'
import './MovieGrid.scss'
import Pagination from '../Pagination/Pagination'
import MovieListGrid from '../MovieListGrid/MovieListGrid'
import { Container, Row, Col } from 'reactstrap'
import FilterListSideBarCountry from '../FilterListSideBar/FilterListSideBarCountry'
import FilterListSideBarGenre from '../FilterListSideBar/FilterListSideBarGenre'
import FilterListSideBar from '../FilterListSideBar/FilterListSideBar'
import { Movie } from '../../store/models/movies'
import { Genre } from '../../store/models/genres'
import { Country, SelectedFilters, YearsFilter } from '../../store/models/common'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from '../../store/reducers'

interface MovieGridProps {
	movies: Movie[]
	genres: Genre[]
	countries: Country[]
	years: YearsFilter
	selectedFilters: SelectedFilters
	setSelectedFilters: (a: any, type: 'countries' | 'genres' | 'years' | 'page') => void
	resetFilter: (type: 'countries' | 'genres') => void
}
const MovieGrid: React.FC<MovieGridProps> = ({
	movies,
	genres,
	countries,
	years,
	selectedFilters,
	setSelectedFilters,
	resetFilter,
}) => {
	const { t } = useTranslation('azcinemaonline')
	const [showGenreSideBar, setShowGenreSideBar] = useState(false)
	const [showCounrySideBar, setShowCountrySideBar] = useState(false)
	const [showYearSideBar, setShowYearSideBar] = useState(false)
	const [showSortSideBar, setShowSortSideBar] = useState(false)
	const is_last_page = useSelector((state: AppState) => state.movies.is_last_page)
	const isFetching = useSelector((state: AppState) => state.movies.isFetching)

	return (
		<div className="MovieGrid">
			{showGenreSideBar && (
				<FilterListSideBarGenre
					onSelect={setSelectedFilters}
					title={t('filter.genre')}
					genres={genres}
					selectedGenres={selectedFilters.genres}
					clickedOutside={() => {
						setShowGenreSideBar(false)
					}}
					reset={(type: 'genres') => {
						resetFilter('genres')
					}}
				/>
			)}
			{showCounrySideBar && (
				<FilterListSideBarCountry
					onSelect={setSelectedFilters}
					title={t('filter.country')}
					countries={countries || []}
					selectedCountries={selectedFilters.countries}
					clickedOutside={() => {
						setShowCountrySideBar(false)
					}}
					reset={(type: 'countries') => {
						resetFilter('countries')
					}}
				/>
			)}
			{showYearSideBar && (
				<FilterListSideBar
					onSelect={setSelectedFilters}
					years={years}
					selectedYears={selectedFilters.years}
					title={t('filter.selectYear')}
					clickedOutside={() => {
						setShowYearSideBar(false)
					}}
				/>
			)}
			<Container fluid={true} style={{ marginBottom: '1rem' }}>
				<Row>
					<Col
						className="filterContainer"
						xs={{ size: 12 }}
						sm={{ size: 6 }}
						md={{ size: 5 }}
						lg={{ size: 5 }}
						xl={{ size: 4 }}
					>
						{genres.length > 1 && (
							<div className="filter mr-1 col-4" onClick={() => setShowGenreSideBar(true)}>
								<span className="filterTitle">{t('filter.genre')}</span>
							</div>
						)}
						{countries.length > 1 && (
							<div className="filter col-4" onClick={() => setShowCountrySideBar(true)}>
								<span className="filterTitle">{t('filter.country')}</span>
							</div>
						)}
						{years.max && years.min && years.max - years.min > 0 && (
							<div className="filter ml-1 col-4" onClick={() => setShowYearSideBar(true)}>
								<span className="filterTitle">{t('filter.year')}</span>
							</div>
						)}
					</Col>
					<Col
						className="filterContainer filterContainerSort"
						xs={{ size: 12 }}
						sm={{ size: 6 }}
						md={{ size: 7 }}
						lg={{ size: 7 }}
						xl={{ size: 8 }}
					>
						{(selectedFilters.genres.length > 0 ||
							selectedFilters.countries.length > 0 ||
							selectedFilters.years.min ||
							selectedFilters.years.max) && (
							<u
								className="mt-1 text-decoration-underline text-danger c-p"
								onClick={() => {
									resetFilter('genres')
									resetFilter('countries')
									setSelectedFilters(
										{
											min: null,
											max: null,
										},
										'years'
									)
								}}
								style={{ cursor: 'pointer' }}
							>
								{t('filter.clearFilter')}
							</u>
						)}
						{/* <div
							className="filter col-3"
							onClick={() => {
								setShowSortSideBar(true)
							}}
						>
							<span className="filterTitle">{t('sort')}</span>
						</div> */}
					</Col>
				</Row>
				<div className="flex-wrap filterRow">
					{selectedFilters.genres.map((genre, index) => {
						return (
							<FilterComponent
								key={index}
								filter={genre}
								onDelete={(filter) => setSelectedFilters(filter, 'genres')}
							/>
						)
					})}
					{selectedFilters.countries.map((genre, index) => {
						return (
							<FilterComponent
								key={index}
								filter={genre}
								onDelete={(filter) => setSelectedFilters(filter, 'countries')}
							/>
						)
					})}
					{(selectedFilters.years.min || selectedFilters.years.max) && (
						<div className="filtredGenre">
							{selectedFilters.years.min} - {selectedFilters.years.max}
							<i
								className="fas fa-times delCross"
								onClick={() => setSelectedFilters({ min: null, max: null }, 'years')}
							></i>
						</div>
					)}
				</div>
			</Container>
			{movies.length === 0 && (
				<Container fluid={true} className="movies-list">
					<h2 className="empty">{t('filter.emtyList')}</h2>
					<u
						className="mt-1 text-decoration-underline text-danger c-p"
						onClick={() => {
							resetFilter('genres')
							resetFilter('countries')
							setSelectedFilters(
								{
									min: null,
									max: null,
								},
								'years'
							)
						}}
						style={{ cursor: 'pointer' }}
					>
						{t('filter.clearFilter')}
					</u>
				</Container>
			)}
			<MovieListGrid movies={movies} />
			{!is_last_page && (
				<Container className="text-center loadMoreContainer">
					<span
						className="loadMore"
						onClick={() => {
							setSelectedFilters(++selectedFilters.page, 'page')
						}}
					>
						<span className="loadMore_text">{t('filter.loadMore')}</span>
						{isFetching ? (
							<span className="loadMore_spinner ml-2">
								<div className="spinner-border loadMoreCircle" role="status"></div>
							</span>
						) : (
							<i className="fas fa-arrow-circle-down ml-2"></i>
						)}
					</span>
				</Container>
			)}

			{/* <Pagination page={4} pagesToDisplay={10} total={15} showFirstLast showPrevNext /> */}
		</div>
	)
}

interface FilterComponentProps {
	filter: Genre | Country
	onDelete: (a: Genre | Country) => void
}

const FilterComponent: React.FC<FilterComponentProps> = ({ filter, onDelete }) => {
	return (
		<div className="filtredGenre">
			{filter.name}
			<i className="fas fa-times delCross" onClick={() => onDelete(filter)}></i>
		</div>
	)
}

export default MovieGrid
