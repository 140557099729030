import { MOVIES_FAILURE, MOVIES_REQUEST, MOVIES_SUCCESS } from '../types'
import { StoreDispatch } from '../store'
import { Dispatch } from '@reduxjs/toolkit'
import { Movie, MoviesFailureAction, MoviesRequestAction, MoviesSuccessAction } from '../models/movies'
import { ErrorArray } from '../models/common'
import { fetchGet } from '../rest/fetch'

const fetchMoviesRequest = (isFetching: boolean): MoviesRequestAction => {
	return {
		type: MOVIES_REQUEST,
		isFetching,
	}
}

const fetchMoviesReceive = (
	movies: Movie[],
	page: number,
	per_page: number,
	is_last_page: boolean
): MoviesSuccessAction => {
	return {
		type: MOVIES_SUCCESS,
		movies,
		page,
		per_page,
		is_last_page,
	}
}

const fetchMoviesError = (errorMessage: ErrorArray): MoviesFailureAction => {
	return {
		type: MOVIES_FAILURE,
		errorMessage,
	}
}

export function fetchMovies(query: string = '', page: number, per_page: number) {
	// let ff: boolean = false
	//debugger
	return async (dispatch: Dispatch<StoreDispatch>) => {
		dispatch(fetchMoviesRequest(true))
		try {
			//debugger
			const response = await fetchGet('movies' + (query ? '?' + query : ''))
			//debugger
			if (response.status === 'ok')
				dispatch(fetchMoviesReceive(response.data.data, page, per_page, !response.data.next_page_url))
			else if (response.status === 'error') dispatch(fetchMoviesError(response.data))
			//debugger
			dispatch(fetchMoviesRequest(false))
		} catch (err) {
			console.log(err)

			//debugger
			dispatch(fetchMoviesError(err as ErrorArray))
			dispatch(fetchMoviesRequest(false))
		}
	}
}
