import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { ReactComponent as Arrow } from '../../assets/right-arrow-of-straight-thin-line.svg'
import './BlogContainer.scss'
import { useTranslation } from 'react-i18next'
import { fetchGet } from '../../store/rest/fetch'
import { Post } from '../../store/models/common'
import { useHistory } from 'react-router'
import { BlogContainerCard } from './BlogContainerCard'

interface BlogContainerProps {}

const BlogContainer: React.FC<BlogContainerProps> = ({}) => {
	const { t, i18n } = useTranslation(['azcinemaonline'])
	const [posts, setPosts] = useState<Post[]>([])
	const history = useHistory()

	useEffect(() => {
		fetchGet('posts')
			.then((res) => {
				res.status == 'ok' && setPosts(res.data.data.slice(0, 6))
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])
	return (
		<>
			<div className="latest-blog">
				<Container fluid={true} className="px-lg-5">
					<Row className="align-items-center">
						<Col className="d-flex justify-content-between pb-4 title" md={{ size: 12 }}>
							<h2>{t('blog.last')}</h2>
							<div
								className="arrowLink ml-auto d-none d-sm-flex align-items-center"
								onClick={() => {
									history.push('/' + t('routes.blog'))
								}}
							>
								<span className="view">{t('blog.view')}</span>
								<Arrow className="straightArrow" />
							</div>
						</Col>
					</Row>
					<Row>
						{posts.map((post, index) => {
							return <BlogContainerCard key={index} post={post} />
						})}
					</Row>
				</Container>
			</div>
		</>
	)
}

export default BlogContainer
