import React from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import CrowdfundingComponent from '../components/CrowdfundingComponent/CrowdfundingComponent'
import i18n from '../i18n'

const ContactUs: React.FC = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Crowdfunding" : lang==='ru' ? "Краудфандинг" : "Kraudfandinq" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	return (
		<>
			<Helmet title={title} />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
			<CrowdfundingComponent />
			<SponsorsContainer />
		</>
	)
}

export default ContactUs
