import React, { Component, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import AboutUsComponent from '../components/AboutUsComponent/AboutUsComponent'
import BlogContainer from '../components/BlogContainer/BlogContainer'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import CategoryContainer from '../components/CategoryContainer/CategoryContainer'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../store/reducers'
import { fetchGenres } from '../store/actions/genres.action'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

const AboutUs: React.FC = () => {
	const lang = i18n.language
	const title = lang === 'en' ? "About Us" : lang==='ru' ? "О нас" : "Haqqımızda" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə" 
	const genres = useSelector((state: AppState) => state.genres.genres)
	const dispatch = useDispatch()
	useEffect(() => {
		if (genres.length === 0) dispatch(fetchGenres())
	}, [])
	return (
		<>
			<Helmet title={title} />
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
			<AboutUsComponent />
			{genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 1
						})[0]
					}
				/>
			)}
			{/* <RandomTrailerBox/> */}
			<BlogContainer />
			<SponsorsContainer />
		</>
	)
}

export default AboutUs
