import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'reactstrap'
import './FollowUs.scss'

interface FollowUsProps {
    
}

const FollowUs: React.FC<FollowUsProps> = ({}) => {
	const { t } = useTranslation('azcinemaonline')
    return (
        <Container xs={12} className='FollowUs'>
            <Col className='titleContainer' xs={12}>
                <div className="title">{t('blog.followus')}</div>
            </Col>

            <Col className='socialContainer' style={{flexWrap:'wrap'}} xs={12}>
                <a href='https://www.facebook.com/azcinemaonline' target='_blank' className="social">
                    <i className="fab fa-facebook-f"></i>
                </a>
                {/* <a href='https://www.youtube.com/channel/UCWMYrbIZA8LhucyRVG5Eh7w' target='_blank' className="social">
                    <i className="fab fa-youtube"></i>
                </a> */}
                <a href='https://www.instagram.com/azcinemaonline' target='_blank' className="social">
                    <i className="fab fa-instagram"></i>
                </a>
            </Col>
        </Container>
    )
}

export default FollowUs;
