import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import BigMovieCard from '../BigMovieCard/BigMovieCard'

import './MovieListGrid.scss'
import { Movie } from '../../store/models/movies'

interface MovieListGridProps {
	close?: () => void
	movies: Movie[]
}

const MovieListGrid: React.FC<MovieListGridProps> = ({ movies, close }) => {
	return (
		<>
			<div className="movies-list">
				<Container fluid={true}>
					<Row>
						{movies.map((movie, index) => {
							return (
								<Col
									key={index}
									className="col-by col-by-7 col-by-6 col-by-5 col-by-4 col-by-3 col-by-2"
									onClick={close}
								>
									<BigMovieCard movie={movie} />
								</Col>
							)
						})}
					</Row>
				</Container>
			</div>
		</>
	)
}

export default MovieListGrid
