import { Dispatch } from 'react'
import { ErrorArray } from '../models/common'
import { Profile, ProfileFailureAction, ProfileRequestAction, ProfileSuccessAction } from '../models/profile'
import { fetchGet } from '../rest/fetch'
import { StoreDispatch } from '../store'
import { PROFILE_FAILURE, PROFILE_REQUEST, PROFILE_SUCCESS } from '../types'

const profileRequest = (isFetching: boolean): ProfileRequestAction => {
	return {
		type: PROFILE_REQUEST,
		isFetching,
	}
}
export const profileDefine = (profile: Profile | null): ProfileSuccessAction => {
	return {
		type: PROFILE_SUCCESS,
		profile: profile,
	}
}
const profileFailure = (message: ErrorArray): ProfileFailureAction => {
	return {
		type: PROFILE_FAILURE,
		errorMessage: message,
	}
}

export const getProfile = () => {
	return async (dispatch: Dispatch<StoreDispatch>) => {
		dispatch(profileRequest(true))
		try {
			const response = await fetchGet('profile')
			if (response.status === 'ok') dispatch(profileDefine(response.data))
			else {
				dispatch(profileDefine(null))
			}
			dispatch(profileRequest(false))
		} catch (err) {
			console.log(err)
			dispatch(profileRequest(false))
		}
	}
}
