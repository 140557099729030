import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb';
import { PageInfoHeader } from '../components/PageInfoHeader/PageInfoHeader';
import PrivacyContainer from '../components/PrivacyContainer/PrivacyContainer';
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer';
import i18n from '../i18n';


const Privacy: React.FC = ({ }) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Privacy" : lang==='ru' ? "Политика конфиденциальности" : "Məxfilik" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
    return (
        <>
            <Helmet title={title} />
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
            <PrivacyContainer />
            <SponsorsContainer/>
        </>
    )
}

export default Privacy
