export default {
	api_url: 'https://api.azcinemaonline.com',
	api_url_withoutSSL: 'http://api.azcinemaonline.com',
	version: 'v1.0',
	player_url: 'https://cdn.jwplayer.com/libraries/utsXyAio.js',
	media_url: 'https://api.azcinemaonline.com/v1.0/media/posters/',
	gadimlieSettings: {
		slidesPerView: 1,
		spaceBetween: 16,
		freeMode: true,
		noSwiping: false,
		breakpoints: {
			1272: {
				slidesPerView: 7,
			},
			1096: {
				slidesPerView: 6,
			},
			920: {
				slidesPerView: 5,
			},
			744: {
				slidesPerView: 4,
			},
			600: {
				slidesPerView: 4,
			},
			512: {
				slidesPerView: 3,
			},
			350: {
				slidesPerView: 2,
			},
		},
	},
	sponsorsSettings: {
		slidesPerView: 1,
		spaceBetween: 16,
		freeMode: true,
		noSwiping: false,
		breakpoints: {
			1272: {
				slidesPerView: 5,
			},
			1096: {
				slidesPerView: 5,
			},
			920: {
				slidesPerView: 5,
			},
			744: {
				slidesPerView: 4,
			},
			600: {
				slidesPerView: 4,
			},
			512: {
				slidesPerView: 3,
			},
			350: {
				slidesPerView: 2,
			},
		},
	},
	crowdfundingSettings: {
		slidesPerView: 1,
		centeredSlides: true,
		freeMode: false,
		noSwiping: false,
		navigation: true,
		Autoplay: true,
	}
}
