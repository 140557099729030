import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import './TermsContainer.scss'

interface TermsContainerProps {}



const TermsContainer: React.FC<TermsContainerProps> = ({ }) => {
    
    const { t, i18n } = useTranslation('terms');
    return(
        <Container className="terms" dangerouslySetInnerHTML={{__html: t('termsAll')}}></Container>
    )
}
export default TermsContainer
