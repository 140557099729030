import React, { useState, useEffect, useRef } from 'react'
import { ReactComponent as PlayButton } from '../../../assets/play-button(1).svg'
import './Trailer.scss'
// @ts-ignore
import ReactJWPlayer from 'react-jw-player'
import { ReactComponent as FullScreenIcon } from '../../../assets/icons/full-screen.svg'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { fetchPost, fetchPostPayment, fetchPostText } from '../../../store/rest/fetch'
import { AppState } from '../../../store/reducers'
import { SETTINGS } from '../../../store/types'
import { CloseButton } from '../../CloseButton/CloseButton'
import config from '../../../config'

interface Props {
	trailerUrl: string | null
	movieUrl: string | null
	cover: string | null
	type: number
	movie_id: number
	price: number | null
	watchlist: boolean | null
	updateData: () => void
}

const Trailer: React.FC<Props> = ({ trailerUrl, cover, movieUrl, type, movie_id, price, watchlist, updateData }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const [playerUrl, setPlayerUrl] = useState('')
	const router = useHistory()
	const fullScreenButton = useRef<HTMLButtonElement>(null)
	const [isFetching, setIsFetching] = useState(false)
	const [modal, setModal] = useState(false)
	const dispatch = useDispatch()
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	const toggle = () => {
		// document.documentElement.classList.toggle('nonScroll')
		setModal(!modal)
	}
	const user = true
	const subsription = false
	useEffect(() => {
		if (trailerUrl) setPlayerUrl(trailerUrl)
	}, [])
	useEffect(() => {
		updateData()
	}, [isAuthenticated])
	const watchMovie = () => {
		if (!isAuthenticated) {
			dispatch({ type: SETTINGS.LOGIN_POPUP, payload: true })
			return
		}
		// @ts-ignore
		const player = window.jwplayer('123')
		if (movieUrl) {
			setPlayerUrl(movieUrl)
			player.setControls(true)
			player.setConfig({
				mute: false,
			})
			setTimeout(() => {
				player.setFullscreen(true)
			}, 100)
			fetchPost({}, 'profile/viewslist/' + movie_id)
		} else {
			if (type === 1) {
				router.push('/' + t('routes.account') + '/' + t('routes.subscription'))
			}
			if (type === 2) {
				document.documentElement.classList.add('nonScroll')
				toggle()
			}
		}
	}
	const onReady = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('123')
		player.setConfig({
			mute: true,
			repeat: true,
		})
		player.play()
		player.setControls(false)
		fullScreenButton.current &&
			(fullScreenButton.current.onclick = () => {
				if (trailerUrl) {
					setPlayerUrl(trailerUrl)
					player.setFullscreen(true)
				}
			})
	}
	const onEnterFullScreen = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('123')
		player.setControls(true)
		player.setConfig({
			mute: false,
		})
	}
	const onExitFullScreen = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('123')
		player.setControls(false)
		player.setConfig({
			mute: true,
		})
	}
	const getTransStatus = (trans_id: string, iteration: number) => {
		fetchPostPayment(
			{
				trans_id,
				is_client: true,
			},
			'payment/pasha'
		)
			.then((res) => {
				if (res.status === 'PENDING' && iteration < 180) {
					setTimeout(() => {
						getTransStatus(trans_id, iteration + 1)
					}, 5000)
				} else if (res.status === 'SUCCESS') {
					setIsFetching(false)
					toast.success(res.data.ErrorMessage, { closeButton: CloseButton })
					updateData()
				} else if (res.status === 'FAILED') {
					setIsFetching(false)
					toast.error(res.data.ErrorMessage, { closeButton: CloseButton })
					updateData()
				}
			})
			.catch((err) => {
				console.log(err)
				if (iteration < 180) {
					setTimeout(() => {
						getTransStatus(trans_id, iteration + 1)
					}, 5000)
				}
			})
	}
	const buyMovie = () => {
		var windowReference = window.open()
		fetchPostText(
			{
				type: 'movie',
				movie_id,
			},
			'payment'
		)
			.then((res: string) => {
				let trans_id_index = res.indexOf('trans_id=')
				let trans_id = res.slice(trans_id_index + 9)

				if (windowReference) {
					windowReference.location.href = res
					getTransStatus(trans_id, 0)
				}
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
				windowReference?.close()
			})
	}
	return (
		<div className="trailer-box">
			<div
				className="watchLaterIcon"
				onClick={() => {
					if (!watchlist) {
						fetchPost({}, 'profile/watchlist/' + movie_id)
					} else {
						fetchPost({}, 'profile/watchlist/' + movie_id + '/remove')
					}
					updateData()
				}}
			>
				<i className={(watchlist ? 'fas' : 'far') + ' fa-bookmark'}></i>
				{t('hotmovie.watchlater')}
			</div>
			{isFetching && (
				<div className="subscriptionSectionLoading">
					<div className="subscriptionSectionLoadingSpinner">
						<div className="spinner-border" role="status"></div>
					</div>
				</div>
			)}
			<div
				className="trailer-content"
				style={{
					backgroundImage: 'url( ' + config.media_url + encodeURI(cover || '') + ')',
				}}
			>
				<div className="" style={{ display: !playerUrl ? 'none' : 'block' }}>
					<ReactJWPlayer
						playerId={'123'}
						playerScript={config.player_url}
						file={playerUrl}
						onReady={onReady}
						onEnterFullScreen={onEnterFullScreen}
						onExitFullScreen={onExitFullScreen}
					/>
				</div>
				<div className="watch-trailer">
					<button
						className="trailerBtn pr-5"
						ref={fullScreenButton}
						style={{ display: trailerUrl ? 'flex' : 'none' }}
					>
						<FullScreenIcon />
						{t('moviedetail.watchtrailer')}
					</button>
					{!(type === 0 && !movieUrl && isAuthenticated) && (
						<div
							className="d-flex align-items-center play-button"
							onClick={() => {
								watchMovie()
							}}
						>
							{movieUrl ? (
								<>
									<PlayButton className="mr-2" />
									<span>{t('hotmovie.movie0')}</span>
								</>
							) : (
								<>
									<span className="mr-1">{'$ '}</span>
									<span
										dangerouslySetInnerHTML={{ __html: t('hotmovie.movie' + type, { price }) }}
									></span>
								</>
							)}
						</div>
					)}
				</div>
			</div>
			<Modal
				wrapClassName="oooo"
				modalClassName="mmmm"
				className="modalWindow"
				isOpen={modal}
				toggle={() => {
					toggle()
					document.documentElement.classList.remove('nonScroll')
				}}
			>
				<ModalHeader
					toggle={() => {
						toggle()
						document.documentElement.classList.remove('nonScroll')
					}}
				>
					{t('hotmovie.confirmation')}
				</ModalHeader>
				<ModalBody>{t('hotmovie.confirmcontent')}</ModalBody>
				<ModalFooter>
					<Button
						className="buttonAgree"
						color="success"
						onClick={() => {
							toggle()
							document.documentElement.classList.remove('nonScroll')
							setIsFetching(true)
							buyMovie()
						}}
					>
						{t('hotmovie.yes')}
					</Button>
					<Button
						color="secondary"
						onClick={() => {
							toggle()
							document.documentElement.classList.remove('nonScroll')
						}}
					>
						{t('hotmovie.cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default Trailer
