import React from 'react'
import { useTranslation } from 'react-i18next'
import './NotFoundComponent.scss'
import { ReactComponent as Arrow } from '../../assets/right-arrow-of-straight-thin-line.svg'
import { useHistory } from 'react-router'
import { Container } from 'reactstrap'

interface NotFoundComponentProps {
	bgImg: string
}

const NotFoundComponent: React.FC<NotFoundComponentProps> = ({ bgImg }) => {
	const { t } = useTranslation('azcinemaonline')
	const history = useHistory()
	return (
		<div className="NotFoundComponent">
			<div className="backGround" style={{ background: bgImg }}>
				<Container className="notFoundText">
					<div className="number">404</div>
					<div className="pageNotFound">{t('notfound.pagetitle')}</div>
					<button onClick={() => history.goBack()} className="returnBackButton p-2">
						<Arrow className="leftArrow" />
						<span className="returnBack">{t('notfound.return')}</span>
					</button>
				</Container>
			</div>
		</div>
	)
}

export default NotFoundComponent
