import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'reactstrap'
import { validateEmail } from '../../helpers'
import { register, authResetError } from '../../store/actions/auth.actions'
import { AppState } from '../../store/reducers'
import { SETTINGS } from '../../store/types'
import './SignUpComponent.scss'

interface SignUpComponentProps {
	display: boolean
}

const SignUpComponent: React.FC<SignUpComponentProps> = ({ display }) => {
	const { t } = useTranslation('azcinemaonline')
	const dispatch = useDispatch()
	const email = useRef<HTMLInputElement>(null)
	const pass = useRef<HTMLInputElement>(null)
	const confirmPass = useRef<HTMLInputElement>(null)
	const agreement = useRef<HTMLInputElement>(null)
	const isFetching = useSelector((state: AppState) => state.auth.isFetching)
	const errorMessages = useSelector((state: AppState) => state.auth.errorMessage)
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)

	const handleSignUp = () => {
		if (isFetching) return
		dispatch(authResetError())
		dispatch(
			register({
				email: email.current?.value || '',
				password: pass.current?.value || '',
				confirm_password: confirmPass.current?.value || '',
			})
		)
	}

	useEffect(() => {
		return () => {
			dispatch(authResetError())
		}
	}, [])

	useEffect(() => {
		if (display) document.documentElement.classList.toggle('nonScroll')
		else document.documentElement.classList.toggle('nonScroll')
	}, [display])

	useEffect(() => {
		isAuthenticated &&
			dispatch({
				type: SETTINGS.SIGNUP_POPUP,
				payload: false,
			})
	}, [isAuthenticated])

	return (
		<Modal
			isOpen={display}
			toggle={() => {
				dispatch({
					type: SETTINGS.SIGNUP_POPUP,
					payload: false,
				})
			}}
			className="my-5 wrapper mx-auto w-100 sign-up"
		>
			<div className="card-login mx-auto">
				{/* {<span className="text-center d-block welcome">{t('welcome')}</span>
			<span className="text-center d-block azcinema">AZ CINEMA ONLINE</span>} */}
				<form
					className="d-flex flex-column justify-content-end"
					onSubmit={(e) => {
						e.preventDefault()
						handleSignUp()
					}}
				>
					<p className="text mb-1 mt-3 text-white">{t('signup.email')}</p>
					<input
						type="text"
						name="email"
						placeholder={t('signup.enteremail')}
						className="field"
						ref={email}
						onChange={() => {
							dispatch(authResetError())
						}}
					/>
					{errorMessages &&
						errorMessages.email &&
						errorMessages.email.map((e, i) => {
							return <small className="error">{e}</small>
						})}
					<p className="text mb-1 mt-3 text-white">{t('signup.password')}</p>
					<input
						type="password"
						name="password"
						placeholder={t('signup.enterpassword')}
						className="field"
						ref={pass}
						onChange={() => {
							dispatch(authResetError())
						}}
					/>
					{errorMessages &&
						errorMessages.password &&
						errorMessages.password.map((e, i) => {
							return <small className="error">{e}</small>
						})}
					<p className="text mb-1 mt-3 text-white">{t('signup.confirm')}</p>
					<input
						type="password"
						name="confirmpassword"
						placeholder={t('signup.enterconfirm')}
						className="field"
						ref={confirmPass}
						onChange={() => {
							dispatch(authResetError())
						}}
					/>
					{errorMessages &&
						errorMessages.confirm_password &&
						errorMessages.confirm_password.map((e, i) => {
							return <small className="error">{e}</small>
						})}
					<input
						type="submit"
						style={{ position: 'absolute', left: '-9999px', width: '1px', height: '1px' }}
						tabIndex={-1}
					/>
				</form>
				<br />
				<button className="login-btn d-flex justify-content-center align-items-center" onClick={handleSignUp}>
					{isFetching ? (
						<div className="spinner-border" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						<span style={{ color: 'white' }}>{t('signup.button')}</span>
					)}
					{/* <span>{t('button')}</span> */}
				</button>
				<p className="password text-center my-3">
					{t('signup.haveaccount')}
					<a
						href="#"
						className="mx-1"
						onClick={(e) => {
							e.preventDefault()
							dispatch({
								type: SETTINGS.SIGNUP_POPUP,
								payload: false,
							})
							dispatch({
								type: SETTINGS.LOGIN_POPUP,
								payload: true,
							})
						}}
					>
						{t('signup.login')}
					</a>
				</p>
				{/* <p className="text-center text-white">{t('or')}</p>
				<div className="soc_buttons">
					<div className="soc">
						<i className="fab fa-facebook-f"></i>
					</div>
					<div className="soc twitter">
						<i className="fab fa-twitter"></i>
					</div>
					<div className="soc">
						<i className="fab fa-google-plus-g"></i>
					</div>
				</div> */}
			</div>
		</Modal>
		// </div>
	)
}

export default SignUpComponent
