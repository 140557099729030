import React from 'react'
import './App.css'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import MainRoutes from './components/MainRoutes.tsx'
import ScrollToTop from './components/ScrollToTop'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LoginComponent from './components/LoginComponent/LoginComponent'
import SignUpComponent from './components/SignUpComponent/SignUpComponent'
import SubscriptionComponent from './components/SubscriptionComponent/SubscriptionComponent'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
	const { i18n } = useTranslation()
	const isLoginOpen = useSelector((state) => state.settings.isLoginOpen)
	const isSignUpOpen = useSelector((state) => state.settings.isSignUpOpen)
	const isSubscriptionOpen = useSelector((state) => state.settings.isSubscriptionOpen)
	return (
		<>
			<Helmet defaultTitle="Az Cinema Online" titleTemplate="%s - Az Cinema" />
			<LoginComponent display={isLoginOpen} />
			<SignUpComponent display={isSignUpOpen} />
			{isSubscriptionOpen && <SubscriptionComponent display={isSubscriptionOpen} />}
			<ToastContainer position="top-center" />
			<BrowserRouter basename={i18n.language}>
				<ScrollToTop>
					<Switch>
						<Route component={MainRoutes} />
					</Switch>
				</ScrollToTop>
			</BrowserRouter>
		</>
	)
}

export default App
