import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Progress } from 'reactstrap'
import '../CrowdfundingDetailComponent.scss'
import mainImg from '../../../assets/about-person-photo.jpeg'
import { Fundings } from '../../../store/models/fundings'

interface AboutPersonProps {
  title: string | null
  cover: string | null
  desc: string | null
  subtitle : string | null
}

const AboutPerson: React.FC<AboutPersonProps> = ({ title, cover, desc, subtitle }) => {
  const { t } = useTranslation(['azcinemaonline'])
  return (
    <Col xs="12" className="p-0 AboutPerson d-flex flex-wrap mt-3">
      <Col xs="12" md="3" className="photo p-0 pr-2 pt-2" id="sticky-photo">
        <div className="photoContainer w-100">
          {cover ? (
            <img src={cover} alt="" className="image" />
          ) : (
            <img src={mainImg} alt="" className="image" />
          )}
        </div>
      </Col>
      <Col xs="12" md="9" className="aboutContent pl-2 p-0">
        <div className="aboutPersonTitle w-100">{title}</div>
        {subtitle ? 
            <div className="aboutPersonSubTitle w-100">{subtitle}</div>
        :
           ""
        }
        <p>{desc}</p>
      </Col>
    </Col>
  )
}

export default AboutPerson
