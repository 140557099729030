import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { CloseButton } from '../CloseButton/CloseButton'
import { Modal } from "reactstrap";
import routes from "../../routes";
import { authResetError, login } from "../../store/actions/auth.actions";
import { AppState } from "../../store/reducers";
import { fetchPost } from "../../store/rest/fetch";
import { SETTINGS } from "../../store/types";
// import { Collapse } from 'reactstrap'
import "./LoginComponent.scss";

interface LoginComponentProps {
  display: boolean;
}

const LoginComponent: React.FC<LoginComponentProps> = ({ display }) => {
  const [forgot, setForgot] = useState(false);
  const { t } = useTranslation("azcinemaonline");
  const dispatch = useDispatch();
  const router = useHistory();
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  // const [isFetching, setIsFetching] = useState(false)
  const errorMessage = useSelector(
    (state: AppState) => state.auth.errorMessage
  );
  const isAuthenticated = useSelector(
    (state: AppState) => state.auth.isAuthenticated
  );
  const isFetching = useSelector((state: AppState) => state.auth.isFetching);
  const [isFetchingNew, setIsFetchingNew] = useState(false)
  const [resetEmail, setResetEmail] = useState("");
  useEffect(() => {
    console.log(email.current?.value);
  }, [email.current?.value]);
  const handleLogin = () => {
    if (isFetching) return;
    if (!email.current || !password.current) return;
    dispatch(authResetError());
    dispatch(
      login({
        email: email.current.value || "",
        password: password.current.value || "",
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(authResetError());
    };
  }, []);

  useEffect(() => {
    if (display) document.documentElement.classList.toggle("nonScroll");
    else document.documentElement.classList.toggle("nonScroll");
  }, [display]);

  useEffect(() => {
    isAuthenticated &&
      dispatch({
        type: SETTINGS.LOGIN_POPUP,
        payload: false,
      });
  }, [isAuthenticated]);

  const sentReset = () => {
    fetchPost({ email: resetEmail }, "forgot-password")
      .then((response) => {
        console.log(response);
        setForgot(false);
        dispatch({
          type: SETTINGS.LOGIN_POPUP,
          payload: false,
        });
		
        if (response.status === "error") {	
            toast.error(response.data.message?.email[0], { closeButton: CloseButton });
            setIsFetchingNew(false);
        } else if (response.status === "ok") {
          typeof response.data.message == "string" &&
            toast.success(response.data.message, { closeButton: CloseButton });
            setIsFetchingNew(false);
        }
      })
      .catch((err) => {
		    // toast.error(err.data.message?.email[0], { closeButton: CloseButton });
        console.log(err.data);
      });
  };

  return (
    <Modal
      isOpen={display}
      toggle={() => {
        dispatch({
          type: SETTINGS.LOGIN_POPUP,
          payload: false,
        });
      }}
      className="my-5 wrapper mx-auto w-100 log-in"
    >
      <div className="card-login mx-auto">
        {/* <span className="text-center d-block welcome">{t('hello')}</span>
				<span className="text-center d-block azcinema">{t('welcome')}</span> */}
        <div className={`${forgot ? "d-none" : ""}`}>
          <form
            className="d-flex flex-column justify-content-end"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <p className="text mb-1 mt-3">{t("login.email")}</p>
            <input
              type="text"
              name="email"
              placeholder={t("login.enteremail")}
              className="field"
              ref={email}
              onChange={() => {
                dispatch(authResetError());
              }}
            />
            <p className=" text mb-1 mt-3">{t("login.password")}</p>
            <input
              type="password"
              name="password"
              placeholder={t("login.enterpassword")}
              className="field"
              ref={password}
              onChange={() => {
                dispatch(authResetError());
              }}
            />
            <input
              type="submit"
              style={{
                position: "absolute",
                left: "-9999px",
                width: "1px",
                height: "1px",
              }}
              tabIndex={-1}
            />
            {errorMessage && errorMessage.message && (
              <small className="error">{errorMessage.message}</small>
            )}
            <p className="mt-3">
              <div
                onClick={() => {
                  setForgot(true);
                }}
                className="forgotPass"
              >
                {t("Forgot Password?")}
              </div>
            </p>
          </form>
          <br />
          <button
            className="login-btn d-flex justify-self-center justify-content-center align-items-center"
            onClick={handleLogin}
          >
            {isFetching ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <span style={{ color: "white" }}>{t("login.button")}</span>
            )}
          </button>
          <p className="password text-center my-3">
            {t("login.haveaccount")}
            <a
              href="#"
              className="mx-1"
              onClick={(e) => {
                e.preventDefault();
                dispatch({
                  type: SETTINGS.LOGIN_POPUP,
                  payload: false,
                });
                dispatch({
                  type: SETTINGS.SIGNUP_POPUP,
                  payload: true,
                });
              }}
            >
              {t("login.signup")}
            </a>
          </p>
          {/* <p className="text-center">{t('or')}</p>
					<div className="soc_buttons">
						<div className="soc">
							<i className="fab fa-facebook-f"></i>
						</div>
						<div className="soc twitter">
							<i className="fab fa-twitter"></i>
						</div>
						<div className="soc">
							<i className="fab fa-google-plus-g"></i>
						</div>
					</div> */}
        </div>

        {/* Forgot Password */}
        <div className={`forgotPassContainer ${forgot ? "" : "d-none"}`}>
          <div className="exit-button d-flex justify-content-end">
            <i
              className="fas fa-times mr-3"
              onClick={() => {
                setForgot(false);
              }}
            ></i>
          </div>
          <form
            className="d-flex flex-column justify-content-end"
            onSubmit={(e) => {
              e.preventDefault();
			  setIsFetchingNew(true)
              sentReset();
            }}
          >
            <p className="text mb-1 mt-3">{t("login.email")}</p>
            <input
              type="text"
              name="email"
              placeholder={t("login.enteremail")}
              className="field"
              onChange={(e) => {
                dispatch(authResetError());
                let value: string = e.target.value;
                setResetEmail(value);
              }}
            />
            <button
              className="login-btn d-flex justify-self-center justify-content-center align-items-center"
              type="submit"
            >
              {isFetchingNew ? (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <span style={{ color: "white" }}>{t("login.forgotSend")}</span>
              )}
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default LoginComponent;
