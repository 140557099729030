import { GenresAction, GenreState } from '../models/genres'
import { GENRES_REQUEST, GENRES_SUCCESS, GENRES_FAILURE } from '../types'

export function genres(
	state: GenreState = {
		isFetching: false,
		genres: [],
		errorMessage: null,
	},
	action: GenresAction
): GenreState {
	switch (action.type) {
		case GENRES_REQUEST:
			return {
				...state,
				isFetching: action.isFetching,
			}
		case GENRES_SUCCESS:
			return {
				...state,
				genres: action.genres,
			}
		case GENRES_FAILURE:
			return {
				...state,
			}
		default:
			return state
	}
}
