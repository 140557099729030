import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Container } from "reactstrap";
import i18n from "../../../../i18n";
import Loading2 from "../../../../pages/Loading2";
import { Movie } from "../../../../store/models/movies";
import { AppState } from "../../../../store/reducers";
import { fetchGet } from "../../../../store/rest/fetch";
import MovieListGrid from "../../../MovieListGrid/MovieListGrid";
import SponsorsContainer from "../../../SponsorsContainer/SponsorsContainer";

interface PurchasedProps {}

export const Purchased: React.FC<PurchasedProps> = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Purchased" : lang === 'ru' ? "Покупки" : "Alınan filmlər"
  const [purchacedMovies, setPurchacedMovies] = useState<Movie[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const isAuthenticated = useSelector(
    (state: AppState) => state.auth.isAuthenticated
  );
  const router = useHistory();
  const { t } = useTranslation("azcinemaonline");
  useEffect(() => {
    setIsFetching(true);
    if (!isAuthenticated) setIsFetching(false);
    else
      fetchGet("profile/purchaced")
        .then((res) => {
          if (res.status === "ok") {
            setPurchacedMovies(res.data);
            setIsFetching(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsFetching(false);
        });
  }, []);
  if (isFetching) return <Loading2 />;
  else
    return (
      <>
	  	<Helmet title={title}/>
        <div className="account">
          <Container fluid={true}>
            <div className="row Purchased">
              <div
                className="backToAccaunt"
                onClick={() => {
                  router.push("/" + t("routes.account"));
                }}
              >
                <i className="fas fa-chevron-left"></i>
                {t("account.backButton")}
              </div>
            </div>
            <h1 className="title">{t("account.purchaced")}</h1>
          </Container>

          <MovieListGrid movies={purchacedMovies} />
          {purchacedMovies.length === 0 && (
            <Container fluid={true} className="movies-list empty">
              <h2 className="empty text-center">
                {t("account.emptyPurchaced")}
              </h2>
            </Container>
          )}

          <SponsorsContainer />
        </div>
      </>
    );
};
