import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { validateEmail } from '../../../helpers'
import { getProfile } from '../../../store/actions/profile.actions'
import { Profile } from '../../../store/models/profile'
import { AppState } from '../../../store/reducers'
import { fetchPost } from '../../../store/rest/fetch'
import { CloseButton } from '../../CloseButton/CloseButton'
interface EmailBoxProps {
	profile: Profile | null
}

const EmailBox: React.FC<EmailBoxProps> = ({ profile }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	const dispatch = useDispatch()
	const [chngEmail, setChngEmail] = useState(false)
	useEffect(() => {
		chngEmail && changeInput && changeInput.current && changeInput.current.select()
	}, [chngEmail])
	const [currentEmail, setCurrentEmail] = useState('')
	const [currentEmailIsVal, setCurrentEmailIsVal] = useState(true)
	const [currentEmailIsFetching, setCurrentEmailIsFetching] = useState(false)
	const changeInput = useRef<HTMLInputElement>(null)

	const updateEmail = () => {
		if (currentEmailIsFetching) return
		setCurrentEmailIsFetching(true)
		// currentEmailIsVal &&
		fetchPost({ email: currentEmail }, 'profile/update')
			.then((res) => {
				dispatch(getProfile())
				if (res.status === 'error') {
					res.data.message.email.map((err: string) => {
						toast.error(err, { closeButton: CloseButton })
					})
				} else if (res.status === 'ok') {
					typeof res.data.message == 'string'
						? toast.success(res.data.message, { closeButton: CloseButton })
						: toast.success(t('account.successfullyСhanged'), { closeButton: CloseButton })
					setChngEmail(false)
					setCurrentEmailIsFetching(false)
				}
			})
			.catch((err) => {
				console.log(err)
				setCurrentEmailIsFetching(false)
			})
	}
	return (
		<div className="d-flex align-items-center col-11 col-sm-9 profileInfo" style={{ flexWrap: 'wrap' }}>
			{!chngEmail ? (
				<>
					<div style={{ paddingLeft: '0' }}>
						<div className="profileName">{profile?.email || 'example@example.com'}</div>
					</div>
					<i
						className="fas fa-pencil-alt ml-3"
						style={{
							fontSize: '1.2rem',
							cursor: 'pointer',
							lineHeight: '1rem',
							color: '#f7711c',
						}}
						onClick={() => {
							setChngEmail(true)
							setCurrentEmail(profile?.email || '')
						}}
					></i>
				</>
			) : (
				<>
					<div style={{ paddingLeft: '0' }}>
						<input
							ref={changeInput}
							value={currentEmail}
							className={'profileNameChange ' + (!currentEmailIsVal && 'wrongEmail')}
							onChange={(e) => {
								!validateEmail(e.target.value)
									? setCurrentEmailIsVal(false)
									: setCurrentEmailIsVal(true)
								setCurrentEmail(e.target.value)
							}}
						/>
					</div>
					<i
						className="fas fa-check ml-3"
						style={{
							fontSize: '1.2rem',
							cursor: 'pointer',
							lineHeight: '1rem',
							color: '#f7711c',
						}}
						onClick={updateEmail}
					></i>
					<i
						className="fas fa-times ml-3"
						style={{
							fontSize: '1.2rem',
							cursor: 'pointer',
							lineHeight: '1rem',
							color: '#f70d1c',
						}}
						onClick={() => {
							setChngEmail(false)
						}}
					></i>
				</>
			)}
		</div>
	)
}

export default EmailBox
