import React, { useState } from 'react'
import './BigMovieCard.scss'

import { ReactComponent as PlayButton } from '../../assets/play-button(1).svg'
import { Movie } from '../../store/models/movies'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useProgressiveImage } from '../../helpers/useProgressiveImage'

interface BigMovieCardProps {
	movie: Movie
}
const BigMovieCard: React.FC<BigMovieCardProps> = ({ movie }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const loaded = useProgressiveImage(encodeURI(movie.poster || ''))
	
	return (
		<>
			<div className="movie-card">
				<Link
					to={'/' + t('routes.movies') + '/' + movie.id}
					className="card"
					style={{
						backgroundImage: `url(${loaded})`,
					}}
				>
					<div className="duration">
						<span className="time">
							{movie.duration} {t('common.minute')}
						</span>
					</div>

					<PlayButton className="play-button" />

					<h3 className="title">{movie.name}</h3>
				</Link>
			</div>
		</>
	)
}

export default BigMovieCard
