import React from 'react';
import GoogleMapReact from 'google-map-react';
import './MapComponent.scss';
import { Col } from 'reactstrap';

interface MapComponentProps {
    address: string,
    latLng: latLng,
}

interface latLng {
    lat: number,
    lng: number,
}

const MapComponent: React.FC<MapComponentProps> = ({ address, latLng: pin }) => {
    return (
        <Col xs={{ size: 12 }} lg={{size: 8}}>
            <div className='map' style={{height:'100%'}}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1518.8616539421398!2d49.857194!3d40.41498!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3244ef48b29a8f34!2sNarimanfilm!5e0!3m2!1sru!2sus!4v1621092344191!5m2!1sru!2sus" width="100%" height="100%" style={{ border: "0" }} loading="lazy"></iframe>
                {/* <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBYxZGSS7GQlZPUkhZh9Yvi9zs0euSRskg' }}
                    defaultCenter={pin}
                    defaultZoom={15}
                /> */}
            </div>
        </Col>
    )
}

export default MapComponent
