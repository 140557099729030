import React from 'react'
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { ReactComponent as Arrow } from "../../assets/right-arrow-of-straight-thin-line.svg";
import './AdvertismentFilms.scss'

interface AdvertismentFilmsProps {

}

const AdvertismentFilms: React.FC<AdvertismentFilmsProps> = ({ }) => {
    const { t, i18n } = useTranslation('azcinemaonline');
    return (
        <Container>
            <div className="maybeInterested">{t("notfound.interest")}</div>
            <div className="bottomLine"></div>
        </Container>
    )
}

export default AdvertismentFilms
