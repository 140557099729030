import React from 'react'
import '../../AccountComponent.scss'
import { Profile } from '../../../../store/models/profile'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { fetchPostText } from '../../../../store/rest/fetch'
interface SubscriptionPeriodProps {
	subsPeriod: {
		oneMonth: boolean
		duration: number
		pricePerMoth: number
		offer?: number
		totalPrice?: number
	}
	subscribe: (duration: number) => void
}

const SubscriptionPeriod: React.FC<SubscriptionPeriodProps> = ({ subsPeriod: subs, subscribe }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const router = useHistory()
	return (
		<div
			className="col-12 col-sm-6 col-lg-3 d-flex flex-row subscriptionPeriodInner"
			onClick={() => {
				subscribe(subs.duration)
			}}
		>
			<div className="subscriptionPeriod">
				{subs.offer && (
					<div className="subscriptionPeriodOfferWrapper">
						<div className="subscriptionPeriodOffer">{subs.offer}%</div>
					</div>
				)}
				<div className="subscriptionPeriodMonth">{subs.duration}</div>
				<div className="subscriptionPeriodPriceBlock">
					<div className="subscriptionPeriodPriceBlockPrice">{subs.pricePerMoth} &#8380;</div>
					<div className="subscriptionPeriodPriceBlockDuration">{t('account.perMonth')}</div>
				</div>
				{subs.oneMonth ? (
					<div className="subscriptionPeriodDescription">{t('account.everyMonth')}</div>
				) : (
					<div className="subscriptionPeriodDescription">
						<span
							dangerouslySetInnerHTML={{
								__html: t('account.subscriptionPeriodDescription', {
									price: subs.totalPrice,
									duration: subs.duration,
								}),
							}}
						></span>
						{subs.duration <= 4
							? t('account.subscriptionPeriodDescriptionOne')
							: t('account.subscriptionPeriodDescriptionMore')}
					</div>
				)}
			</div>
		</div>
	)
}

export default SubscriptionPeriod
