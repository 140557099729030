import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { useProgressiveImage } from '../../helpers/useProgressiveImage'
import { Post } from '../../store/models/common'

interface BlogPageContainerCardProps {
	post: Post
}

export const BlogPageContainerCard: React.FC<BlogPageContainerCardProps> = ({ post }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const loaded = useProgressiveImage(encodeURI(post.preview || ''))
	var date = new Date (post.created_at);
	
	return (
		<Col className="blog-block col-by col col-sm-6 col-md-6">
			<div className="blog-box">
				<Link
					to={'/' + t('routes.blog') + '/' + post.id}
					className="blog-content"
					style={{
						backgroundColor: '#0e111a',
						backgroundImage: `url(${loaded})`,
					}}
				>
					<div className='text-white'>{date.toLocaleDateString()}</div>
					<h3 className="title">{post.title}</h3>
				</Link>
			</div>
		</Col>
	)
}
