import React, { useEffect, useState } from 'react'
import { YearsFilter } from '../../store/models/common'
import OutsideClick from '../OutsideClick'
import './FilterListSideBar.scss'
import ReactSlider from 'react-slider'
import { useTranslation } from 'react-i18next'

interface FilterListSideBarProps {
	onSelect: (years: YearsFilter, type: 'years') => void
	clickedOutside: () => void
	title: string
	years: YearsFilter
	selectedYears: YearsFilter
}

const FilterListSideBar: React.FC<FilterListSideBarProps> = ({
	clickedOutside,
	years,
	title,
	onSelect,
	selectedYears,
}) => {
	const [className, setClassName] = useState('')

	useEffect(() => {
		setTimeout(() => {
			setClassName('active')
		}, 10)
		document.documentElement.classList.add('nonScroll')
		return () => {
			document.documentElement.classList.remove('nonScroll')
		}
	}, [])
	const exit = () => {
		setClassName('')
		setTimeout(() => {
			clickedOutside()
		}, 500)
	}
	const [tempYears, setTempYears] = useState({
		min: selectedYears.min || years.min || 1970,
		max: selectedYears.max || years.max || 2030,
	})
	const { t } = useTranslation('azcinemaonline')
	return (
		<div className={'FilterListSideBar ' + className}>
			<OutsideClick
				clickedOutside={() => {
					exit()
				}}
			>
				<div className={'filterListSideBar container ' + className}>
					<i
						className="fas fa-times closeCross"
						onClick={() => {
							exit()
						}}
					/>
					<h2 className="offset-sm-1 text-left-sm">{title}</h2>
					<div className="w-100">
						<div className="flex-wrap offset-sm-1 col-sm-10 ">
							<div className="d-flex flex-direction-row justify-content-between text-dark font-weight-bold my-2">
								<p className="mb-n1 yearFilterHeader">{tempYears.min || years.min || 1970}</p>
								<p className="mb-n1 yearFilterHeader">{tempYears.max || years.max || 2030}</p>
							</div>
							<ReactSlider
								className="slider my-2"
								min={years.min || 1970}
								max={years.max || 2030}
								trackClassName="yearsFilterTrack"
								value={[tempYears.min || years.min || 1970, tempYears.max || years.max || 2030]}
								pearling
								onChange={(value) => {
									if (Array.isArray(value))
										setTempYears({
											min: value[0],
											max: value[1],
										})
								}}
							/>
							<div className="d-flex flex-direction-row justify-content-between text-muted small mb-n2 ">
								<p className="custom-muted mb-t1">{t('filter.from')}</p>
								<p className="custom-muted mb-t1">{t('filter.to')}</p>
							</div>
							<div className="d-flex justify-content-between mt-3">
								<div
									className="clearYearFilter mt-1"
									onClick={() => {
										exit()
										onSelect(
											{
												min: null,
												max: null,
											},
											'years'
										)
									}}
								>
									{t('filter.clearFilter')}
								</div>
								<div
									className="makeYearFilter d-flex justify-content-center p-2 text-white font-weight-bold"
									onClick={() => {
										exit()
										onSelect(
											{
												min: tempYears.min,
												max: tempYears.max,
											},
											'years'
										)
									}}
								>
									{t('filter.findNow')}
								</div>
							</div>
						</div>
					</div>
				</div>
			</OutsideClick>
		</div>
	)
}

interface FilterProps {
	filter: string
	id: number
}
const Filter: React.FC<FilterProps> = ({ filter, id }) => {
	return (
		<div className="d-inline-block boldWeight">
			<input type="checkbox" id={'hiddenInput' + id} hidden />
			<label className="filtredGenre" htmlFor={'hiddenInput' + id}>
				{filter}
			</label>
		</div>
	)
}

export default FilterListSideBar
