import React from 'react'
import { Helmet } from 'react-helmet'
import LoadingComponent from '../components/LoadingComponent/LoadingComponent'

const Loading: React.FC = ({}) => {
	return (
		<>
			<LoadingComponent />
		</>
	)
}

export default Loading
