import React, { Component, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import AdvertismentFilms from '../components/AdvertismentFilms/AdvertismentFilms'
import AdvertismentFilmsContainer from '../components/AdvertismentFilmsContainer/AdvertismentFilmsContainer'
import CategoryContainer from '../components/CategoryContainer/CategoryContainer'
import NotFoundComponent from '../components/NotFoundComponent/NotFoundComponent'
import { PageInfoHeaderForNotFound } from '../components/PageInfoHeader/PageInfoHeader'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { fetchGenres } from '../store/actions/genres.action' 
import { AppState } from '../store/reducers'

const NotFound: React.FC = () => {
	const genres = useSelector((state: AppState) => state.genres.genres)
	const dispatch = useDispatch()
	useEffect(() => {
		genres.length === 0 && dispatch(fetchGenres())
	}, [])
	return (
		<>
			<Helmet title="404" />
			<NotFoundComponent bgImg="https://picsum.photos/1600/500"/>
			{/* <PageInfoHeaderForNotFound bgImg="https://picsum.photos/1600/500" /> */}
			<AdvertismentFilms />
			<AdvertismentFilmsContainer />
			{genres.length !== 0  && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 23
						})[0]
					}
				/>
			)}
			<SponsorsContainer />
		</>
	)
}

export default NotFound
