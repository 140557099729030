import React, { useEffect } from 'react'
import { PageInfoHeader } from '../components/PageInfoHeader/PageInfoHeader'
import { Helmet } from 'react-helmet'
import AccountComponent from '../components/AccountComponent/AccountComponent'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../store/actions/profile.actions'
import { AppState } from '../store/reducers'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

const Account: React.FC = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Account" : lang==='ru' ? "Личный кабинет" : "Şəxsi kabinet" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	const dispatch = useDispatch()
	const profile = useSelector((state: AppState) => state.profile.profile)
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	useEffect(() => {
		dispatch(getProfile())
	}, [isAuthenticated])
	return (
		<>
			<Helmet title={title} />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
			<AccountComponent profile={profile} />
			<SponsorsContainer />
		</>
	)
}

export default Account
