import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row } from 'reactstrap'
import { fetchGenres } from '../../store/actions/genres.action'
import { AppState } from '../../store/reducers'
import CategoryContainer from '../CategoryContainer/CategoryContainer'
import { MediumMovieCardForMovieGrid } from '../MediumMovieCard/MediumMovieCard'
import './AdvertismentFilmsContainer.scss'

interface AdvertismentFilmsContainerProps {

}

const AdvertismentFilmsContainer: React.FC<AdvertismentFilmsContainerProps> = ({ }) => {
  let movies = [
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
    {
      poster: "https://picsum.photos/200/",
    },
  ]
  const genres = useSelector((state: AppState) => state.genres.genres)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchGenres())
  }, [])
  return (
    <>
      {genres.length !== 0 && (
        <CategoryContainer
          genre={
            genres.filter((i) => {
              return i.id === 4
            })[0]
          }
        />
      )}

      {/* id:10 */}
      {genres.length !== 0 && (
        <CategoryContainer
          genre={
            genres.filter((i) => {
              return i.id === 10
            })[0]
          }
        />
      )}
      {genres.length !== 0 && (
        <CategoryContainer
          genre={
            genres.filter((i) => {
              return i.id === 22
            })[0]
          }
        />
      )}

      {/* <RandomTrailerBox/> */}
      {/* id:15 */}
      {genres.length !== 0 && (
        <CategoryContainer
          genre={
            genres.filter((i) => {
              return i.id === 15
            })[0]
          }
        />
      )}
      {/* id:2 */}
      {genres.length !== 0 && (
        <CategoryContainer
          genre={
            genres.filter((i) => {
              return i.id === 2
            })[0]
          }
        />
      )}
    </>
  )
}

export default AdvertismentFilmsContainer
