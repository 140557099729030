import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Progress } from 'reactstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import './CrowdfundingComponent.scss'
import previewPhoto from '../../assets/photo.jpg'
import sliderPhoto from '../../assets/banner/homebanner1.png'
import eduPhoto from '../../assets/education.jpeg'
import veteran from '../../assets/veteran-kino.jpeg'
import { fetchGet } from '../../store/rest/fetch'
import { Fundings } from '../../store/models/fundings'
import { Link } from 'react-router-dom'
import { useProgressiveImage } from '../../helpers/useProgressiveImage'
import CrowdfundingCard from './CrowdfundingCard/CrowdfundingCard'



interface CrowdfundingComponentProps {}

const CrowdfundingComponent: React.FC<CrowdfundingComponentProps> = () => {
	const { t } = useTranslation(['azcinemaonline'])
    const [ fundings, setFundings ] = useState<Fundings | null>(null)
    const [ type, setType ]  = useState(0)
    useEffect(()=> {
        getCrowdfundList()
    }, [])

    const getCrowdfundList = () => {
        fetchGet('fundings')
            .then((response) => {
                if(response.status === 'ok' ) {
                    setFundings(response.data.data);
                }
            })
            .catch((err) => {
				console.log(err)
			})
    }
	return (
		<Container fluid className="CrowdfundingComponent d-flex flex-wrap">
            {/* <i className="fas fa-chevron-right"></i> */}
			<Col className='sliderContainer p-2' xs='12' md='6'>
                    <Swiper slidesPerView={1}
                            centeredSlides={true}
                            freeMode={false}
                            noSwiping={false}
                            navigation={true}
                            autoplay={true}
                            className='h-100 p-0'>
                        <SwiperSlide style={{width: "113% !important"}}>
                            <div className="slider-slide w-100 h-100">
                                <img src={sliderPhoto} alt="" className="sliderPhoto" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slider-slide w-100 h-100">
                                <img src={sliderPhoto} alt="" className="sliderPhoto" />   
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slider-slide w-100 h-100">
                                <img src={sliderPhoto} alt="" className="sliderPhoto" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="slider-slide w-100 h-100">
                                <img src={sliderPhoto} alt="" className="sliderPhoto" />
                            </div>
                        </SwiperSlide>
					</Swiper>
            </Col>
			<Col className='informationContainer flex-wrap d-flex p-2' xs='12' md='6'>
                <Col className='p-2' xs='12'>
                    <div className="aboutPlatform">
                        <div className="aboutTitle mb-2 col-12">
                            {t("crowdfunding.abouttitle")}
                        </div>
                        <div className="aboutContent col-12 mb-2">
                            {t("crowdfunding.aboutcontent")}
                            <p>{t("crowdfunding.aboutcontent1")}</p>
                            <p>{t("crowdfunding.aboutcontent2")}</p>
                            <p>{t("crowdfunding.aboutcontent3")}</p>
                            <p>{t("crowdfunding.aboutcontent4")}</p>
                        </div>
                        <div className="col-12 d-flex contactButtonContainer">
                            <a href={t("routes.contactus")} className="col-12 contactButton">
                                {t("crowdfunding.aboutbutton")}
                            </a>
                        </div>
                    </div>
                </Col>
                <Col className='p-2' xs='12' sm='6'>
                    <div className="infoContainer orange-border d-flex justify-content-center">
                        <div className="infoNumber">1</div>
                        <div className="infoTitle">{t('crowdfunding.activeProj')}</div>
                    </div>
                </Col>
                <Col className='p-2' xs='12' sm='6'>
                    <div className="infoContainer orange-border d-flex justify-content-center">
                        <div className="infoNumber">0</div>
                        <div className="infoTitle">{t('crowdfunding.totalMoney')}</div>
                    </div>
                </Col>
                <Col className='p-2' xs='12' sm='6'>
                    <div className="infoContainer orange-border d-flex justify-content-center">
                        <div className="infoNumber">0</div>
                        <div className="infoTitle">{t('crowdfunding.endedProj')}</div>
                    </div>
                </Col>
                <Col className='p-2' xs='12' sm='6'>
                    <div className="infoContainer orange-border d-flex justify-content-center">
                        <div className="infoNumber">0</div>
                        <div className="infoTitle">{t('crowdfunding.stockMoney')}</div>
                    </div>
                </Col>
            </Col>
            <div className='w-100 d-flex flex-wrap'>
                <Col xs='12'>
                    <div className="title">{t("crowdfunding.title")}</div>
                </Col>
                {fundings ? fundings.map((funding) => {
                    // if(funding.type === 'student') {
                    //     setType(1)
                    // } else if(funding.type === 'veteran') {
                    //     setType(2)
                    // } else if(funding.type === 'cinema') {
                    //     setType(3)
                    // }
                    return (
                        <CrowdfundingCard 
                            id={funding.id}
                            title={funding.title}
                            photo={funding.cover}
                            short={funding.short}
                            type={funding.type}
                            total={funding.total}
                            budget={funding.budget}
                        />
                    )
                }) : '<></>'}
                
                
                
            </div>
            {/* <Container className="text-center loadMoreContainer">
					<span className="loadMore">
						<span className="loadMore_text">{t('filter.loadMore')}</span>
						<i className="fas fa-arrow-circle-down ml-2"></i>
					</span>
				</Container> */}
		</Container>
	)
}

export default CrowdfundingComponent
