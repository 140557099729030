import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import "../ResetPassComponent/ResetPassComponent.scss";

interface ExpireComponentProps {

}

const ExpireComponent: React.FC<ExpireComponentProps> = ({}) => {
  const { t } = useTranslation("azcinemaonline");
  const router = useHistory();

  return (
    <Container fluid={true} className="SettingsPage">
        <div className="ResetPass">
          <Row>
            <div
              onClick={() => {
                router.push("/" + t("routes.account"));
              }}
              className="backToAccaunt "
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-chevron-left"></i>
              {t("account.backButton")}
            </div>
          </Row>
          <div className="timeisover mt-3 mb-5">
            {t('account.Time to reset the password is over!')}
          </div>
        </div>
    </Container>
  );
};

export default ExpireComponent;


