export enum LanguagesI {
	en = 'en',
	ru = 'ru',
	az = 'az',
}
export interface LanguageRoutesI {
	home: string
	privacy: string
	terms: string
	aboutus: string
	faq: string
	contactus: string
	blog: string
	movies: string
	tv: string
	collections: string
	account: string
	subscription: string
	purchased: string
	watchLater: string
	views: string
	settingsPage: string
	crowdfunding: string
}
export type Routes = {
	[key in LanguagesI]: LanguageRoutesI | any
}

const en: LanguageRoutesI = {
	home: '',
	privacy: 'privacy-and-policy',
	terms: 'terms-and-condidition',
	aboutus: 'about-us',
	faq: 'faq',
	contactus: 'contact-us',
	blog: 'news',
	movies: 'movies',
	collections: 'collections',
	tv: 'tv-chanels',
	account: 'account',
	subscription: 'subscription',
	purchased: 'purchased',
	watchLater: 'watch-later',
	views: 'views',
	settingsPage: 'settings',
	crowdfunding: 'crowdfunding',
}
const az: LanguageRoutesI = {
	home: '',
	privacy: 'gizlilik-ve-siyaset',
	terms: 'qaydalar-ve-shertler',
	aboutus: 'haqqimizda',
	faq: 'sual-cavab',
	contactus: 'elaqe',
	blog: 'xeberler',
	movies: 'filmler',
	collections: 'kolleksiyalar',
	tv: 'tv-kanallar',
	account: 'akkaunt',
	subscription: 'abunechilik',
	purchased: 'alish',
	watchLater: 'sonra-bax',
	views: 'baxilmishlar',
	settingsPage: 'tenzimlemeler',
	crowdfunding: 'kraudfandinq',
}
const ru: LanguageRoutesI = {
	home: '',
	privacy: 'politika-i-konfidensialnost',
	terms: 'usloviya-i-pravila',
	aboutus: 'o-nas',
	faq: 'faq',
	contactus: 'kontakti',
	blog: 'novosti',
	movies: 'filmi',
	collections: 'podborki',
	tv: 'tv-kanali',
	account: 'akkaunt',
	subscription: 'podpiska',
	purchased: 'kuplenniye',
	watchLater: 'posmotret-pozje',
	views: 'prosmotrenniye',
	settingsPage: 'nastroyki',
	crowdfunding: 'kraudfaundinq',
}
const routes: Routes = {
	en: en,
	ru: ru,
	az: az,
}
export default routes
