import { AuthAction, AuthState } from '../models/auth'
import { AUTH_ERROR_RESET, AUTH_FAILURE, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS } from '../types'

export const auth = (
	state: AuthState = {
		isAuthenticated: localStorage.getItem('token') ? true : false,
		errorMessage: null,
		isFetching: false,
	},
	action: AuthAction
): AuthState => {
	switch (action.type) {
		case AUTH_REQUEST:
			return { ...state, isFetching: action.isFetching }
		case AUTH_FAILURE:
			return { ...state, errorMessage: action.errorMessage }
		case AUTH_ERROR_RESET:
			return { ...state, errorMessage: null }
		case AUTH_SUCCESS:
			return { ...state, isAuthenticated: true }
		case AUTH_LOGOUT:
			return { ...state, isAuthenticated: false }
		default:
			return state
	}
}
