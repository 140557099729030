import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { PageInfoHeader } from '../components/PageInfoHeader/PageInfoHeader';
import TermsContainer from '../components/TermsContainer/TermsContainer';
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer';
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const Terms: React.FC = ({ }) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Terms" : lang==='ru' ? "Условия" : "Şərtlər" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
    return (
        <>
            <Helmet title={title} />
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
            <TermsContainer />
            <SponsorsContainer />
        </>
    )
}

export default Terms
