import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import CategoryContainer from '../components/CategoryContainer/CategoryContainer'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { Col, Container, Row } from 'reactstrap'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import Trailer from '../components/MovieDetail/Trailer/Trailer'
import MovieHeader from '../components/MovieDetail/MovieTitle/MovieHeader'
import MovieDescription from '../components/MovieDetail/MovieDescription/MovieDescription'
import CastsSlider from '../components/MovieDetail/CastsSlider/CastsSlider'
import { useHistory, useParams } from 'react-router'
import { fetchGet } from '../store/rest/fetch'
import { Movie } from '../store/models/movies'
import { useTranslation } from 'react-i18next'
import Loading from './Loading'
import { Genre } from '../store/models/genres'
import { Country, Figure } from '../store/models/common'
import Loading2 from './Loading2'
import i18n from '../i18n'

interface RouteParams {
	id: string
}
const movieUrls = ['https://content.jwplatform.com/manifests/146UwF4L.m3u8', '', '']

const MovieDetail: React.FC = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? 'Movie Detail' : lang === 'ru' ? 'Фильм' : 'Film'
	const moviesTitle = lang === 'en' ? 'Movies' : lang === 'ru' ? 'Фильмы' : 'Filmlər'
	const homeTitle = lang === 'en' ? 'Home' : lang === 'ru' ? 'Главная' : 'Ana səhifə'
	const { t } = useTranslation(['azcinemaonline'])
	const [movie, setMovie] = useState<Movie | null>(null)
	const [genres, setGenres] = useState<Genre[]>([])
	const [countries, setCountries] = useState<Country[]>([])
	const [figures, setFigures] = useState<Figure[]>([])
	const [isFetching, setIsFetching] = useState(false)
	const { id } = useParams<RouteParams>()
	const router = useHistory()
	useEffect(() => {
		setMovie(null)
		getMovieData()
	}, [id])
	const getMovieData = () => {
		setIsFetching(true)
		fetchGet('movies/' + id)
			.then((response) => {
				if (response.status === 'ok') {
					setMovie(response.data)
					setIsFetching(false)
					fetchGet('movies/' + id + '/genres')
						.then((res) => {
							res.status === 'ok' && setGenres(res.data.data)
						})
						.catch((err) => console.log(err))
					fetchGet('movies/' + id + '/countries')
						.then((res) => {
							res.status === 'ok' && setCountries(res.data)
						})
						.catch((err) => console.log(err))
					fetchGet('movies/' + id + '/figures')
						.then((res) => {
							res.status === 'ok' && setFigures(res.data)
						})
						.catch((err) => console.log(err))
				} else {
					if (response.data.code === 404) router.replace('/404')
				}
			})
			.catch((err) => {
				router.push('/movies')
				console.log(err)
			})
	}
	if (!movie) return <Loading2 />
	else {
		const movieMedia = {
			trailerUrl: movie.trailer,
			cover: movie.cover,
			movieUrl: movie.movie,
			type: movie.type,
			movie_id: movie.id,
			price: movie.price_for_buy,
			watchlist: movie.watchlist,
		}
		return (
			<>
				<Helmet title={movie.name + (movie.year ? ` (${movie.year})` : '') || title} />
				<BreadCrumb title="Movie Detail">
					<BreadCrumbItem name={homeTitle} to="/" />
					<BreadCrumbItem name={moviesTitle} to={'/' + t('routes.movies')} />
					{genres && genres[0] && <BreadCrumbItem name={genres[0].name} to={genres[0].slug} />}
					<BreadCrumbItem name={movie.name} current={true} />
				</BreadCrumb>

				<div className="movie-details">
					<Container fluid={'lg'}>
						<MovieHeader movie={movie} genres={genres} />
						<Row>
							<Col md={12}>
								<Trailer {...movieMedia} updateData={getMovieData} />
							</Col>
						</Row>
						<MovieDescription movie={movie} genres={genres} countries={countries} />
						{figures.length > 0 && <CastsSlider casts={figures} />}
					</Container>
				</div>
				{genres.length !== 0 && <CategoryContainer genre={genres[0]} />}
				<SponsorsContainer />
			</>
		)
	}
}

export default MovieDetail

{
	/* <MovieDetailTrailer />
	<MovieDescriptionContainer /> */
}

{
	/*             
	<CommentComponent />
	<Container>
			<Col xs={{ size: 12 }} lg={{ size: 9 }}>
					<CommentWriterContainer />
			</Col>
	</Container> */
}
