import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import CommentWriterContainer from '../CommentWriterContainer/CommentWriterContainer'
import ContactForm from '../ContactForm/ContactForm'
import MapComponent from '../MapComponent/MapComponent'
import './ContactUsAdress.scss'

interface ContactUsAdressProps {}

const ContactUsAdress: React.FC<ContactUsAdressProps> = () => {
	const { t } = useTranslation(['azcinemaonline'])
	return (
		<Container className="ContactUsAdress">
			<Row className="justify-content-center">
				<MapComponent address="Yasamal, Sharifzadeh" latLng={{ lat: 40.3421, lng: 49.83401 }} />
				<Col xs={{ size: 12 }} lg={{ size: 4 }} className="infoPart">
					<div className="w-100 card mt-4 mt-lg-0">
						<Row className="align-items-center">
							<Col xs={{ size: 12 }} md={{ size: 6 }} lg={{ size: 12 }} className="pb-4 pb-lg-0">
								<div className="getInTouch">
									<i className="far fa-user getInTouchIcon" />
									<span className="getInTouchTitle">{t('contactus.getintouch')}</span>
								</div>
								<div className=" infoPartContent">{t('contactus.content')}</div>
							</Col>
							<Col xs={{ size: 12 }} md={{ size: 6 }} lg={{ size: 12 }} className="d-flex">
								<div className="infoContainer mt-4 mb-4">
									<div className="adressContainer my-2 row">
										<div className="col-md-2">
											<div className="adressContainerIconContainer">
												<i className="fas fa-map-marker-alt adressContainerIcon" />
											</div>
										</div>
										<div className="col-md-9">
											<div className="adressTitle">
												{t('contactus.adress1')} {t('contactus.adress2')} {t('contactus.adress3')}
												{t('contactus.adress4')}
											</div>
										</div>
									</div>
									<div className="mailContainer my-2 row">
										<div className="col-md-2">
											<div className="mailContainerIconContainer">
												<i className="fas fa-envelope-open-text mailContainerIcon" />
											</div>
										</div>
										<div className="col-md-9">
											<div className="mailTitle">
												<span>info@azcinemaonline.com</span>
												<br />
												<span>mail@narimanfilm.az</span>
											</div>
										</div>
									</div>
									<div className="phoneContainer my-2 row">
										<div className="col-md-2">
											<div className="phoneContainerIconContainer">
												<i className="fas fa-phone-alt phoneContainerIcon" />
											</div>
										</div>
										<div className="col-md-9">
											<div className="phoneTitle">
												<a href="tel:+994124916379" className="phone">
													+994 12 491 63 79
												</a>
												<br />
												<a href="tel:+994502163265" className="phone">
													+994 50 216 32 65
												</a>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
			<Row style={{justifyContent:'center', marginTop:'4rem', marginBottom:'3rem'}}>
				<Col xs={{ size: 8 }} style={{paddingLeft:'0', paddingRight:'0'}}>
					<ContactForm />
					{/* <CommentWriterContainer /> */}
				</Col>
			</Row>
		</Container>
	)
}

export default ContactUsAdress
