import { useEffect, useState } from 'react'
import config from '../config'

export const useProgressiveImage = (src: string) => {
	const [sourceLoaded, setSourceLoaded] = useState('')

	useEffect(() => {
		setSourceLoaded('')
		const srcTemp = config.media_url + src
		const img = new Image()
		img.src = srcTemp
		img.onload = () => setSourceLoaded(srcTemp)
	}, [src])

	return sourceLoaded
}
