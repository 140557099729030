import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Progress } from 'reactstrap'
import { fetchGet } from '../../../store/rest/fetch'
import { Donators } from '../../../store/models/donators'
import '../CrowdfundingDetailComponent.scss'



interface DonatorsComponentProps {
  id: number,
  display_name: string,
  description: string,
  email: string,
  donate: number,
  anonym: boolean
}

const DonatorsComponent: React.FC<DonatorsComponentProps> = ({id, display_name, description, email, donate, anonym}) => {
  const { t } = useTranslation(['azcinemaonline'])
    return (
        <div className='infoBannerContainer DonatorsComponent my-3'>
      
        <div className="titleContainer d-flex justify-content-between">

        {display_name ? 
          <div className="donatorName">{display_name}</div>
          :
          <div className='donatorName'>Anonymus</div>
          }
        <div className="donatorMoney">{donate} ₼</div>
        </div>
        <div className="contentContainer">
        {description}
        </div>
        </div>
      )
}

export default DonatorsComponent
