import { SettingsAction, SettingsState } from '../models/settings'
import { SETTINGS } from '../types'

export function settings(
	state: SettingsState = {
		isLoginOpen: false,
		isSignUpOpen: false,
		isSubscriptionOpen: false
	},
	action: SettingsAction
): SettingsState {
	switch (action.type) {
		case SETTINGS.LOGIN_POPUP:
			return { ...state, isLoginOpen: action.payload }
		case SETTINGS.SIGNUP_POPUP:
			return { ...state, isSignUpOpen: action.payload }
		case SETTINGS.SUBSCRIPTION_POPUP:
			return { ...state, isSubscriptionOpen: action.payload }
		default:
			return state
	}
}
