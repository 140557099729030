import React, { useEffect, useState } from 'react'
import OutsideClick from '../OutsideClick'
import './FilterListSideBar.scss'
import { Country } from '../../store/models/common'
import { useTranslation } from 'react-i18next'

interface FilterListSideBarProps {
	clickedOutside: () => void
	countries: Country[]
	selectedCountries: Country[]
	title: string
	onSelect: (country: Country, type: 'countries') => void
	reset: (type: 'countries') => void
}

const FilterListSideBar: React.FC<FilterListSideBarProps> = ({
	clickedOutside,
	countries,
	selectedCountries,
	title,
	onSelect,
	reset,
}) => {
	const [className, setClassName] = useState('')

	useEffect(() => {
		setTimeout(() => {
			setClassName('active')
		}, 10)
		document.documentElement.classList.add('nonScroll')
		return () => {
			document.documentElement.classList.remove('nonScroll')
		}
	}, [])
	const exit = () => {
		setClassName('')
		setTimeout(() => {
			clickedOutside()
		}, 500)
	}

	const { t } = useTranslation('azcinemaonline')
	return (
		<div className={'FilterListSideBar ' + className}>
			<OutsideClick
				clickedOutside={() => {
					exit()
				}}
			>
				<div className={'filterListSideBar container ' + className}>
					<i
						className="fas fa-times closeCross"
						onClick={() => {
							exit()
						}}
					/>
					<h2 className="offset-sm-1 text-left-sm">{title}</h2>
					<div className="w-100">
						<div className="flex-wrap offset-sm-1 col-sm-10">
							{countries.map((country, index) => {
								return (
									<Filter
										key={index}
										id={index}
										isActive={selectedCountries.filter((i) => i.id === country.id).length > 0}
										filter={country}
										onClick={onSelect}
									/>
								)
							})}
							<div className="d-flex justify-content-between mt-3">
								<div
									className="clearYearFilter mt-1"
									onClick={() => {
										exit()
										reset('countries')
									}}
								>
									{t('filter.clearFilter')}
								</div>
								<div
									className="makeYearFilter d-flex justify-content-center p-2 text-white font-weight-bold"
									onClick={() => {
										exit()
									}}
								>
									{t('filter.findNow')}
								</div>
							</div>
						</div>
					</div>
				</div>
			</OutsideClick>
		</div>
	)
}

interface FilterProps {
	isActive: boolean
	onClick: (country: Country, type: 'countries') => void
	filter: Country
	id: number
}

const Filter: React.FC<FilterProps> = ({ filter, id, onClick, isActive }) => {
	let className = 'filtredGenre'
	isActive && (className += ' active')
	return (
		<div
			className="d-inline-block boldWeight"
			onClick={() => {
				onClick(filter, 'countries')
			}}
		>
			<span className={className}>{filter.name}</span>
		</div>
	)
}

export default FilterListSideBar
