import React, { useEffect, useState } from 'react'
import './SponsorsContainer.scss'

import { Col, Container } from 'reactstrap'
import { Swiper, SwiperSlide } from 'swiper/react'

import NarimanFilm from '../../assets/sponsors/narimanfilm.svg'
import AzerbaijanFilm from '../../assets/sponsors/azerbaijanfilm.png'
import Medeniyyet from '../../assets/sponsors/medeniyyet-svg.svg'

import 'swiper/swiper.scss'
import config from '../../config'

interface SponsorsContainerProps {}

const SponsorsContainer: React.FC<SponsorsContainerProps> = () => {
	const [swiper, setSwiper] = useState<any>(null)
	const [mySponsors, setMySponsors] = useState<any[]>([])
	useEffect(() => {
		setMySponsors([
			{
				logo: Medeniyyet,
				url: 'http://mct.gov.az/',
				name: 'NarimanFilm',
			},
			{
				logo: AzerbaijanFilm,
				url: 'https://www.azerbaijanfilm.az',
				name: 'AzerbaijanFilm',
			},
			{
				logo: NarimanFilm,
				url: 'http://narimanfilm.az',
				name: 'NarimanFilm',
			},
		])
	}, [])
	useEffect(() => {
		swiper && swiper.update()
		setTimeout(() => {
			swiper && swiper.update()
		}, 500)
	}, [mySponsors, mySponsors.length, swiper])
	return (
		<div className="sponsors">
			<Container fluid={true}>
				{/* <Swiper {...config.sponsorsSettings} onSwiper={(swiper) => setSwiper(swiper)}></Swiper> */}
				<div className="row">
					{mySponsors.map((sponsor, index) => {
						return (
							<Col xs={6} sm={4} md={3} xl={2} className="sponsor-box" key={index}>
								<a
									href={sponsor.url}
									target="_blank"
									rel="noreferrer"
									className="sponsor-logo"
									style={
										{
											// backgroundImage: 'url(' + sponsor.logo + ')',
										}
									}
								>
									<img src={sponsor.logo} alt="" />
									<span className="name">{sponsor.name}</span>
								</a>
							</Col>
						)
					})}
				</div>
			</Container>
		</div>
	)
}

export default SponsorsContainer
