import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { logout } from '../../../store/actions/auth.actions'
interface LogoutProps {}
const Logout: React.FC<LogoutProps> = ({}) => {
	const { t } = useTranslation(['azcinemaonline'])
	const dispatch = useDispatch()
	return (
		<div className="col-1 col-sm-3 d-flex justify-content-end align-items-center" style={{ padding: '0' }}>
			<div
				className="edit d-flex"
				style={{
					cursor: 'pointer',
					color: 'black',
				}}
				onClick={() => {
					dispatch(logout())
				}}
			>
				<i
					className="fas fas fa-sign-out-alt"
					style={{
						fontSize: '1.2rem',
						cursor: 'pointer',
						lineHeight: '1.45rem',
						color: '#f7711c',
					}}
				></i>
				<div className="redact" style={{ fontWeight: 'bold', marginLeft: '0.6rem' }}>
					{t('account.logout')}
				</div>
			</div>
		</div>
	)
}

export default Logout
