export const validateEmail = (email: any) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

//                         [1   2    3    4    5    6      7 ]
export const windowSizes = [0, 392, 512, 744, 920, 1096, 1272]
export const windowView = [20, 20, 18, 20, 20, 18, 21]
export const calculateCount = (width: number) => {
	let temp: number = 0
	windowSizes.map((size, index) => {
		if (width >= size) {
			if (windowSizes[index + 1] && width < windowSizes[index + 1]) temp = windowView[index]
			else temp = windowView[index]
		}
	})
	return temp
}
