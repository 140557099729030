import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import BlogDetailContentContainer from '../components/BlogDetailContentContainer/BlogDetailContentContainer'
import { PageInfoHeader } from '../components/PageInfoHeader/PageInfoHeader'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import { fetchGet } from '../store/rest/fetch'
import { Category, Post } from '../store/models/common'
import { useHistory, useParams } from 'react-router'
import Loading from './Loading'
import i18n from '../i18n'

interface RouteParams {
	id: string
}

const BlogDetail: React.FC = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Blog" : lang==='ru' ? "Блог" : "Bloq"
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	const { t } = useTranslation(['azcinemaonline'])
	const [post, setPost] = useState<Post>()
	const [categories, setCategories] = useState<Category[]>([])
	const { id } = useParams<RouteParams>()
	const history = useHistory()
	useEffect(() => {
		fetchGet('posts/' + id)
			.then((res) => {
				res.status == 'ok' ? setPost(res.data) : history.push('/404')
			})
			.catch((err) => {
				console.log(err)
			})
		fetchGet('posts/' + id + '/categories')
			.then((res) => {
				res.status == 'ok' ? setCategories(res.data.data) : history.push('404')
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])
	if (!post) return <Loading />
	else
		return (
			<>
				<Helmet title={post.title} />
				<BreadCrumb title="Blog Detail">
					<BreadCrumbItem name={homeTitle} to="/" />
					<BreadCrumbItem name={title} to={'/' + t('routes.blog')} />
					<BreadCrumbItem name={post.title} current={true} />
				</BreadCrumb>
				<BlogDetailContentContainer post={post} categories={categories} />
				<SponsorsContainer />
			</>
		)
}

export default BlogDetail
