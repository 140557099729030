import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import CrowdfundingDetailComponent from '../components/CrowdfundingDetailComponent/CrowdfundingDetailComponent'
import i18n from '../i18n'
import { useParams } from 'react-router'
import { fetchGet } from '../store/rest/fetch'
import { Fundings } from '../store/models/fundings'

interface RouteParams {
	id: string
}

const ContactUs: React.FC = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Crowdfunding Detail" : lang==='ru' ? "Краудфандинг Детали" : "Kraudfandinq detalı" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	const [ funding, setFunding ] = useState<Fundings | null>(null)
	const { id } = useParams<RouteParams>()
	useEffect(() => {
		fetchGet('fundings/' + id)
			.then((res) => {
				setFunding(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])
	return (
		<>
			<Helmet title={title} />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
			<CrowdfundingDetailComponent funding={funding}/>
			<SponsorsContainer />
		</>
	)
}

export default ContactUs
