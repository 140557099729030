import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import GenresList from '../components/GenresList/GenresList'
import CategoryContainer from '../components/CategoryContainer/CategoryContainer'
import UpcomingMoviesContainer from '../components/UpcomingMoviesContainer/UpcomingMoviesContainer'
import BlogContainer from '../components/BlogContainer/BlogContainer'
import SmallMovieCardListContainer from '../components/SmallMovieCardListContainer/SmallMovieCardListContainer'
import RandomTrailerBox from '../components/RandomTrailerBox/RandomTrailerBox'
import PopularMovieSlider from '../components/PopularMovieSlider/PopularMovieSlider'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import HotMovie from '../components/Banners/HotMovie/HotMovie'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../store/reducers'
import { fetchGenres } from '../store/actions/genres.action'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'



const Home: React.FC = ({}) => {
	const lang = i18n.language
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	const hotMover = {
		title: (lang === 'en') ? "The Precinct" : ( lang==='ru' ) ? "Участок" : "Sahə",
	}
	const genres = useSelector((state: AppState) => state.genres.genres)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(fetchGenres())
	}, [])
	return (
		<>
			<Helmet title={homeTitle} />
			<PopularMovieSlider />

			<GenresList genres={genres} />
			{/* id:4 */}

			{genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 4
						})[0]
					}
				/>
			)}
			{/* <UpcomingMoviesContainer/> */}

			{/* id:10 */}
			{genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 10
						})[0]
					}
				/>
			)}

			<HotMovie {...hotMover} />
			{/* id:22 */}
			{genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 22
						})[0]
					}
				/>
			)}

			{/* <RandomTrailerBox/> */}
			{/* id:15 */}
			{/* {genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 15
						})[0]
					}
				/>
			)} */}
			{/* id:2 */}
			{genres.length !== 0 && (
				<CategoryContainer
					genre={
						genres.filter((i) => {
							return i.id === 2
						})[0]   
					}
				/>
			)}

			{/* <SmallMovieCardListContainer/> */}

			<BlogContainer />
			<SponsorsContainer />
		</>
	)
}

export default Home
