import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { Col, Container, Row } from 'reactstrap'
import i18n from '../../../../i18n'
import { fetchPost } from '../../../../store/rest/fetch'
import { CloseButton } from '../../../CloseButton/CloseButton'
import './Settings.scss'

interface SettingsPageProps {}

export const SettingsPage: React.FC<SettingsPageProps> = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Settings" : lang === 'ru' ? "Настройки" : "Ayarlar"
	const router = useHistory()
	const { t } = useTranslation(['azcinemaonline'])
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const [isFetching, setIsFetching] = useState(false)
	const [errors, setErrors] = useState({
		password: -1,
		confirmpass: -1,
	})
	const chngPass = () => {
		if (isFetching || Object.values(errors).includes(0) || Object.values(errors).includes(-1)) return
		setIsFetching(true)
		if (errors.password === errors.confirmpass && errors.password === 1) {
			newPassword &&
				fetchPost({ password: newPassword, confirm_password: confirmNewPassword }, 'profile/update/password')
					.then((res) => {
						setIsFetching(false)
						if (res.status === 'error') {
							res.data.message.map((err: string) => {
								toast.error(err, { closeButton: CloseButton })
							})
						} else if (res.status === 'ok') {
							typeof res.data.message == 'string'
								? toast.success(res.data.message, { closeButton: CloseButton })
								: toast.success(t('account.successfullyСhanged'), { closeButton: CloseButton })
							router.push('/' + t('routes.account'))
							setNewPassword('')
							setConfirmNewPassword('')
							setErrors({
								password: -1,
								confirmpass: -1,
							})
						}
					})
					.catch((err) => {
						setIsFetching(false)
						console.log(err)
					})
		}
	}
	return (
		<>
			<Helmet title={title}/>
			<div className="account">
				<Container fluid={true} className="SettingsPage">
					<Row>
						<div
							onClick={() => {
								router.push('/' + t('routes.account'))
							}}
							className="backToAccaunt "
						>
							<i className="fas fa-chevron-left"></i>
							{t('account.backButton')}
						</div>
					</Row>
					<Row className="bigRow ">
						<Col xs={12} className="title">
							{t('account.change')}
						</Col>

						<Col md={5}>
							<div
								className={
									'inputContainer ' +
									(errors.password === 0 ? 'wrong' : errors.password === 1 ? 'correct' : '')
								}
								>
								<div className="inputTitle">
									{t('account.newpass')} <span className="required">*</span>
								</div>
								<input
									className="input"
									type="password"
									name="newPassword"
									placeholder={t('account.placenewpass')}
									value={newPassword}
									onChange={(e) => {
										let value: string = e.target.value
										value.length > 5
										? setErrors((prev) => {
											return { ...prev, password: 1 }
										})
										: setErrors((prev) => {
											return { ...prev, password: 0 }
										})
										value == confirmNewPassword
										? setErrors((prev) => {
											return { ...prev, confirmpass: 1 }
											})
											: setErrors((prev) => {
												return { ...prev, confirmpass: 0 }
											})
											setNewPassword(value)
									}}
									/>
							</div>
						</Col>
						<Col md={5}>
							<div
								className={
									'inputContainer ' +
									(errors.confirmpass === 0 ? 'wrong' : errors.confirmpass === 1 ? 'correct' : '')
								}
							>
								<div className="inputTitle">
									{t('account.confirmpass')} <span className="required">*</span>
								</div>
								<input
									className={
										'input ' +
										(errors.confirmpass === 0 ? 'wrong' : errors.confirmpass === 1 ? 'correct' : '')
									}
									placeholder={t('account.placeconfirm')}
									type="password"
									value={confirmNewPassword}
									onChange={(e) => {
										let value: string = e.target.value
										newPassword === value
										? setErrors({ ...errors, confirmpass: 1 })
										: setErrors({ ...errors, confirmpass: 0 })
										
										setConfirmNewPassword(value)
									}}
									/>
							</div>
						</Col>
						<Col md={2}>
							<div className="submitContainer">
								<button className="submitButton" onClick={chngPass}>
									{t('account.submit')}
									{isFetching && (
										<span className="custom_spinner ml-2">
											<div className="spinner-border spinner_circle" role="status"></div>
										</span>
									)}
								</button>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}
