import React from 'react'
import { useHistory } from 'react-router'
interface SettingLinksComponentProps {
	title: string
	link: string
	icon: string
}
const SettingLinksComponent: React.FC<SettingLinksComponentProps> = ({ title, link, icon }) => {
	const history = useHistory()
	return (
		<div className="col-6 col-sm-6 col-md-4 col-lg-2 col-xxl-1 d-flex flex-row settingLinks">
			<div
				className="profileTopInner profileSettings"
				style={{ width: '100%', cursor: 'pointer' }}
				onClick={() => {
					history.push(link)
				}}
			>
				<i className={icon} style={{ marginBottom: '0.4rem' }}></i>
				<div className="settingTitle" style={{ fontWeight: 'bold' }}>
					{title}
				</div>
			</div>
		</div>
	)
}

export default SettingLinksComponent
