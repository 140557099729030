import { Dispatch } from 'react'
import {
	AuthFailureAction,
	AuthLogoutAction,
	AuthRequestAction,
	AuthResetErrorAction,
	AuthSuccessAction,
	UserToLogin,
	UserToRegister,
} from '../models/auth'
import { ErrorArray } from '../models/common'
import { fetchPost } from '../rest/fetch'
import { StoreDispatch } from '../store'
import { AUTH_ERROR_RESET, AUTH_FAILURE, AUTH_LOGOUT, AUTH_REQUEST, AUTH_SUCCESS } from '../types'

const authRequest = (isFetching: boolean): AuthRequestAction => {
	return {
		type: AUTH_REQUEST,
		isFetching,
	}
}
const authSuccess = (): AuthSuccessAction => {
	return {
		type: AUTH_SUCCESS,
	}
}
const authFailure = (message: ErrorArray): AuthFailureAction => {
	return {
		type: AUTH_FAILURE,
		errorMessage: message,
	}
}
export const authResetError = (): AuthResetErrorAction => {
	return {
		type: AUTH_ERROR_RESET,
	}
}
export const authLogout = (): AuthLogoutAction => {
	return {
		type: AUTH_LOGOUT,
	}
}
export const register = (user: UserToRegister) => {
	return async (dispatch: Dispatch<StoreDispatch>) => {
		dispatch(authRequest(true))
		try {
			const response = await fetchPost(user, 'register')
			if (response.status === 'ok') {
				localStorage.setItem('token', response.data.token)
				dispatch(authSuccess())
			} else if (response.status === 'error') dispatch(authFailure(response.data))
			dispatch(authRequest(false))
		} catch (err) {
			console.log(err)
			dispatch(authRequest(false))
		}
	}
}
export const login = (user: UserToLogin) => {
	return async (dispatch: Dispatch<StoreDispatch>) => {
		dispatch(authRequest(true))
		try {
			const response = await fetchPost(user, 'login')
			if (response.status === 'ok') {
				localStorage.setItem('token', response.data.token)
				dispatch(authSuccess())
			} else if (response.status === 'error') dispatch(authFailure(response.data))
			dispatch(authRequest(false))
		} catch (err) {
			console.log(err)
			dispatch(authRequest(false))
		}
	}
}
export const logout = () => {
	localStorage.removeItem('token')
	return async (dispatch: Dispatch<StoreDispatch>) => {
		dispatch(authLogout())
		try {
			const response = await fetchPost({}, 'logout')
			if (response.status === 'error') dispatch(authFailure(response.data))
		} catch (err) {
			dispatch(authFailure(err as ErrorArray));
		}
	}
}