import { addAuthorization } from './addAuthorization'
import { addJsonContentType } from './addJsonContentType'
import { handleJsonResponse, handleVoidResponse } from './handleResponse'
import config from '../../config'

export async function fetchGet(url: string): Promise<any> {
	let response: Response
	try {
		response = await fetch(`${config.api_url}/${config.version}/${url}`, await addAuthorization())
	} catch (error) {
		response = await fetch(`${config.api_url_withoutSSL}/${config.version}/${url}`, await addAuthorization())
	}
	return await handleJsonResponse(response)
}

export async function fetchPost<TEntityPost>(data: TEntityPost, url: string): Promise<any> {
	let response: Response
	try {
		response = await fetch(
			`${config.api_url}/${config.version}/${url}`,
			addJsonContentType(
				await addAuthorization({
					method: 'POST',
					body: JSON.stringify(data),
				})
			)
		)
	} catch (error) {
		response = await fetch(
			`${config.api_url_withoutSSL}/${config.version}/${url}`,
			addJsonContentType(
				await addAuthorization({
					method: 'POST',
					body: JSON.stringify(data),
				})
			)
		)
	}
	return await handleJsonResponse(response)
}
export async function fetchPostPayment<TEntityPost>(data: TEntityPost, url: string): Promise<any> {
	let response: Response
	try {
		response = await fetch(
			`${config.api_url}/${url}`,
			addJsonContentType(
				await addAuthorization({
					method: 'POST',
					body: JSON.stringify(data),
				})
			)
		)
	} catch (error) {
		response = await fetch(
			`${config.api_url_withoutSSL}/${url}`,
			addJsonContentType(
				await addAuthorization({
					method: 'POST',
					body: JSON.stringify(data),
				})
			)
		)
	}
	return await handleJsonResponse(response)
}
export async function fetchPostText<TEntityPost>(data: TEntityPost, url: string): Promise<any> {
	let response: Response
	try {
		response = await fetch(
			`${config.api_url}/${config.version}/${url}`,
			addJsonContentType(
				await addAuthorization({
					method: 'POST',
					body: JSON.stringify(data),
				})
			)
		)
		if (response.ok) {
			let text = await response.text()
			return text
		} else {
			return null
		}
	} catch (error) {
		response = await fetch(
			`${config.api_url_withoutSSL}/${config.version}/${url}`,
			addJsonContentType(
				await addAuthorization({
					method: 'POST',
					body: JSON.stringify(data),
				})
			)
		)
		if (response.ok) {
			let text = await response.text()
			return text
		} else {
			return null
		}
	}
}

export async function fetchPut<TEntityPut>(data: TEntityPut, url: string): Promise<any> {
	let response: Response
	try {
		response = await fetch(
			url,
			addJsonContentType(
				await addAuthorization({
					method: 'PUT',
					body: JSON.stringify(data),
				})
			)
		)
	} catch (error) {
		response = await fetch(
			url,
			addJsonContentType(
				await addAuthorization({
					method: 'PUT',
					body: JSON.stringify(data),
				})
			)
		)
	}
	return await handleJsonResponse(response)
}

export async function fetchDelete(url: string): Promise<void> {
	let response: Response
	try {
		response = await fetch(url, addJsonContentType(await addAuthorization({ method: 'DELETE' })))
	} catch (error) {
		response = await fetch(url, addJsonContentType(await addAuthorization({ method: 'DELETE' })))
	}
	await handleVoidResponse(response)
}
