import { Movie, MoviesAction, MoviesState } from '../models/movies'
import { MOVIES_REQUEST, MOVIES_SUCCESS, MOVIES_FAILURE } from '../types'

export function moviesReducer(
	state: MoviesState = {
		isFetching: false,
		movies: [],
		errorMessage: null,
		is_last_page: false,
	},
	action: MoviesAction
): MoviesState {
	switch (action.type) {
		case MOVIES_REQUEST:
			return {
				...state,
				isFetching: action.isFetching,
			}
		case MOVIES_SUCCESS:
			let movies: Movie[] = []
			
			if (!Array.isArray(action.movies)) movies = Object.values(action.movies)
			else movies = action.movies

			let newMovies: Movie[] = state.movies.slice()
			if (action.page === 1) newMovies = []
			newMovies.splice((action.page - 1) * action.per_page, movies.length, ...movies)
			return {
				...state,
				movies: newMovies,
				is_last_page: action.is_last_page,
			}
		case MOVIES_FAILURE:
			return {
				...state,
				errorMessage: action.errorMessage,
			}
		default:
			return state
	}
}
