import React, { Component, useRef } from 'react'
import { Container, Row, Col } from 'reactstrap'

import { ReactComponent as PlayButton } from '../../../assets/play-button(1).svg'

// @ts-ignore
import ReactJWPlayer from 'react-jw-player'
// @ts-ignore
// import SahaTrailer from '../../../assets/trailers/trailer-the-precinict-ilgar-safat.mp4'
// @ts-ignore
import TrailerVideo from '../../../assets/trailers/trailer.mp4'

import './HotMovie.scss'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import config from '../../../config'

interface HotMovieProps {
	title: string
}

const HotMovie: React.FC<HotMovieProps> = ({ title }) => {
	const { t } = useTranslation('azcinemaonline')
	const fullScreenButton = useRef<HTMLButtonElement>(null)
	const router = useHistory()

	const onReady = (event: any) => {
		// debugger
		// @ts-ignore
		const player = window.jwplayer('123')
		player.setConfig({
			mute: true,
		})
		player.play()
		player.setControls(false)
		// @ts-ignore
		fullScreenButton.current.onclick = () => {
			player.setFullscreen(true)
		}
	}
	const onEnterFullScreen = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('123')
		player.setControls(true)
		player.setConfig({
			mute: false,
		})
	}
	const onExitFullScreen = (event: any) => {
		// @ts-ignore
		const player = window.jwplayer('123')
		player.setControls(false)
		player.setConfig({
			mute: true,
		})
	}
	const goToMovie = () => {
		router.push('/' + t('routes.movies') + '/' + 216) //movie.id)
	}

	return (
		<>
			<div className="HotMovie">
				<Container fluid={true} className="px-lg-5">
					<div className="hot-movie-banner">
						<ReactJWPlayer
							className={'hot-movie'}
							playerId={'123'}
							playerScript={config.player_url}
							file={'https://api.azcinemaonline.com/v1.0/streams/hu4z0mpXvg8ZrSn/hu4z0mpXvg8ZrSn.m3u8'}
							onReady={onReady}
							onEnterFullScreen={onEnterFullScreen}
							onExitFullScreen={onExitFullScreen}
						/>
						<div className="d-flex align-items-end hot-movie">
							<div className="d-flex justify-content-between align-items-center meta">
								<div className="title">
									<span>{title}</span>
								</div>
								<div className="d-flex align-items-center actions">
									<button ref={fullScreenButton} className="d-none d-md-inline mr-md-5 watch-trailer">
										<span>{t('hotmovie.trailer')}</span>
									</button>
									<a
										href="#"
										className="d-flex align-items-center play-button"
										onClick={(e) => {
											e.preventDefault()
											goToMovie()
										}}
									>
										<PlayButton className="mr-2" />
										<span>{t('hotmovie.movie0')}</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default HotMovie
