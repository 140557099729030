import React, { Component, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import { Category } from '../../store/models/common'
import { fetchGet } from '../../store/rest/fetch'
import './BlogFilterContainer.scss'

interface BlogFilterContainerProps {
	categories: Category[]
}

const BlogFilterContainer: React.FC<BlogFilterContainerProps> = ({ categories }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpen2, setIsOpen2] = useState(false)
	const { t } = useTranslation(['azcinemaonline'])

	const toggle = () => setIsOpen(!isOpen)
	return (
		<div className="blogDetails container" style={{ paddingRight: '0', paddingLeft: '0' }}>
			<div id="accordion" className="blogCategories mt-n4  mt-md-0 mb-4">
				<div className="card" style={{ backgroundColor: 'transparent' }}>
					<div
						className="card-header"
						id="headingOne"
						data-toggle="collapse"
						onClick={toggle}
						data-target="#collapseOne"
						aria-expanded="true"
						aria-controls="collapseOne"
					>
						<h5 className="mb-0">
							<button
								className="btn btn-link cardTitle"
								data-toggle="collapse"
								data-target="#collapseOne"
								aria-expanded="true"
								aria-controls="collapseOne"
							>
								<i className="fas fa-list-ul"> </i>
								<span>{t('blog.all-categories')}</span>
							</button>
						</h5>
					</div>
					<Collapse isOpen={true}>
						<div className="card-body">
							<ul className="px-3">
								{categories.map((category) => {
									return (
										<li className="categoryLinks" key={category.id}>
											<Link to={'/' + t('routes.blog') + '/' + category.slug}>
												{category.name}
											</Link>
										</li>
									)
								})}
							</ul>
							{/* <hr /> */}
							{/* <a className="pl-3">
                <i className="fas fa-plus plus" />
                <span>{t("more-categories")}</span>
              </a> */}
						</div>
					</Collapse>
				</div>
			</div>
		</div>
	)
}

export default BlogFilterContainer
