import React from 'react'
import { Link } from "react-router-dom";
import { Container } from 'reactstrap';
import './BreadCrumb.scss'

interface BreadCrumbProps {
    title: string
}

interface BreadCrumbItemProps {
    name?: string,
    current?: boolean,
    to?: string
}

export const BreadCrumb: React.FC<BreadCrumbProps> = ({ title, children }) => {
    return (
        <div className="BreadCrumb">
            <Container fluid={true}>
                <ul>{children}</ul>
            </Container>
        </div>
    )
}

export const BreadCrumbItem: React.FC<BreadCrumbItemProps> = ({ name, current, to }) => {
    return (
        <li className={current ? "active" : ""}>
            {to ? <Link className="breadCr" to={to}>{name}</Link>
                : <span className="breadCr">{name}</span>
            }
        </li>
    )
}

