import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import config from '../../../config'
import { Figure } from '../../../store/models/common'

import './CastsSlider.scss'

interface CastsSliderProps {
	casts: Figure[]
}

const CastsSlider: React.FC<CastsSliderProps> = ({ casts }) => {
	const { t } = useTranslation(['azcinemaonline'])

	const [swiper, setSwiper] = useState<any>(null)
	useEffect(() => {
		swiper && swiper.update()
	}, [casts, casts.length, swiper])
	return (
		<div className="casts-slider">
			<h2>{t('moviedetail.cast')}</h2>
			<Swiper {...config.gadimlieSettings} onSwiper={(swiper) => setSwiper(swiper)}>
				{casts.map((cast, index) => {
					return (
						<SwiperSlide key={index}>
							<a className="cast-box">
								<div
									className="cast-content"
									style={{
										backgroundImage: 'url(' + cast.preview + ')',
									}}
								></div>
							</a>

							<h3 className="cast-title">{cast.original}</h3>
							<div className="cast-position">{cast.position}</div>
						</SwiperSlide>
					)
				})}
			</Swiper>
		</div>
	)
}

export default CastsSlider
