import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Container, Row } from 'reactstrap'
import i18n from '../../../../i18n'
import { Movie } from '../../../../store/models/movies'
import { AppState } from '../../../../store/reducers'
import { fetchGet } from '../../../../store/rest/fetch'
import MovieListGrid from '../../../MovieListGrid/MovieListGrid'
import SponsorsContainer from '../../../SponsorsContainer/SponsorsContainer'

interface WatchLaterProps {}

export const WatchLater: React.FC<WatchLaterProps> = ({}) => {
	const lang = i18n.language
	const title = lang === 'en' ? "Watch later" : lang === 'ru' ? "Смотреть позже" : "Sonra izlə"
	const [watchLaterMovies, setWatchLaterMovies] = useState<Movie[]>([])
	const [isFetching, setIsFetching] = useState(false)
	const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated)
	const router = useHistory()
	const { t } = useTranslation('azcinemaonline')
	useEffect(() => {
		setIsFetching(true)
		if (!isAuthenticated) setIsFetching(false)
		else
			fetchGet('profile/watchlist')
				.then((res) => {
					if (res.status === 'ok') {
						setWatchLaterMovies(res.data)
						setIsFetching(false)
					}
				})
				.catch((err) => {
					console.log(err)
					setIsFetching(false)
				})
	}, [])
	return (
		<>
			<Helmet title={title} />
			<div className="account">
				<Container fluid={true}>
					<Row className="WatchLater">
						<div
							className="backToAccaunt"
							onClick={() => {
								router.push('/' + t('routes.account'))
							}}
						>
							<i className="fas fa-chevron-left"></i>
							{t('account.backButton')}
						</div>
					</Row>
					<h1 className="title">{t('account.watchLater')}</h1>
				</Container>
				<MovieListGrid movies={watchLaterMovies} />
				{watchLaterMovies.length === 0 && (
					<Container fluid={true} className="movies-list empty">
						<h2 className="empty text-center my-5">{t('account.emptyWatchLater')}</h2>
					</Container>
				)}
				<SponsorsContainer />
			</div>
		</>
	)
}
