import { ReactComponent } from '*.svg'
import React, { useRef } from 'react'
import { Col, Row } from 'reactstrap'
import poster from '../../../assets/Astronaut.jpg'

// @ts-ignore
import ShowMoreText from 'react-show-more-text'

import '../SerialDetail.scss'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

interface Props {
	// movie: Movie
	// genres: Genre[]
	// countries: Country[]
}
const SerialDescription: React.FC<Props> = ({ /*movie, genres, countries*/ }) => {
    const { t } = useTranslation(['azcinemaonline'])
    const showMoreButton = useRef<HTMLDivElement>(null)
    const executeOnClick = (isExpanded: any) => {
		if (showMoreButton.current !== null) {
			showMoreButton.current.classList.toggle('expanded')
		}
	}
    const router = useHistory()
	return (
		<div className="serial-description d-flex flew-wrap mb-5" >
			<div className="poster-container col-3 pl-0">
                <img src={poster} alt="" className='poster-image' />
            </div>

            <div className="description-container col-9">
            <Col className="col-12 p-0">
					<div className="meta clearfix">
						<div className="country">
							<span>{t('moviedetail.country')}:</span>
                            <span
                                className="countryName"
                                // onClick={() => {
                                // 	router.push('/' + t('routes:movies') + '/' + c.name)
                                // }}
                            >
                                Azerbaijan
                            </span>
							{/* {countries.map((c, i) => {
								return (
									<span
										className="countryName"
										key={i}
										// onClick={() => {
										// 	router.push('/' + t('routes:movies') + '/' + c.name)
										// }}
									>
										{c.name}
									</span>
								)
							})} */}
						</div>
						<div className="year">
							<span>{t('moviedetail.year')}: </span>
							<span
								className="movieYear"
								// onClick={() => {
								// 	router.push('/' + t('routes.movies'), { year: movie.year })
								// }}
							>
								2021
							</span>
						</div>
						<div className="time">
							{/* {movie.duration} */}
                            220 {t('common.minute')}
						</div>
					</div>
					<div className="description" ref={showMoreButton}>
						<ShowMoreText
							lines={3}
							more="Show more"
							less="Show less"
							anchorClass="show-more-button"
							onClick={executeOnClick}
							expanded={false}
						>
							Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut voluptatibus fugiat cum ipsam incidunt hic mollitia explicabo, quasi accusamus corporis dolorem nam, quis dolorum eaque esse magni expedita necessitatibus? Aperiam.
                            Illo atque quaerat id officiis quisquam. Ex error repellat illo quis esse, rem, similique nostrum dicta quas voluptatum laborum excepturi blanditiis. Neque dolor molestiae nam fugit facilis magni, unde quae!
                            Libero saepe ad, recusandae eos consequuntur dolorum laborum facilis enim debitis inventore excepturi praesentium ullam aperiam? Fugiat deserunt delectus excepturi voluptates nemo perferendis voluptatem neque commodi? Corporis dolore quam quos?
                            Alias quae cumque optio. Quo magnam corporis praesentium repudiandae vel nemo eligendi esse dolorem aliquid? Est numquam temporibus ratione corrupti, eum, reprehenderit consequuntur atque nesciunt veritatis nulla aliquam reiciendis pariatur?
						</ShowMoreText>
					</div>
				</Col>
            </div>
            
		</div>
	)
}

export default SerialDescription
