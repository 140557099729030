import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Category, Post } from '../../store/models/common'
import './BlogDetailContentContainer.scss'
import BlogFilterContainer from '../BlogFilterContainer/BlogFilterContainer'
import FollowUs from '../FollowUs/FollowUs'
import SubscriptionStatus from '../Subscription/Subscription'
import { useHistory } from 'react-router'

interface BlogDetailContentContainerProps {
	post: Post
	categories: Category[]
}

const BlogDetailContentContainer: React.FC<BlogDetailContentContainerProps> = ({ post, categories }) => {
	const { t } = useTranslation('azcinemaonline')
	var date = new Date (post.created_at);
	return (
		<div className="container BlogDetailContentContainer">
			<div className="container blogDetails w-100 mt-5">
				<div className="row blogDetailsWrap">
					<div className="col-md-8">
						<div className="blogTitle pt-5">{post.title}</div>
						<div className="mt-3">{date.toLocaleDateString()}</div>
						<div className="blogBody pt-4" dangerouslySetInnerHTML={{ __html: post.content }}></div>
						<a
							href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}
							target="_blank"
						>
							{t('blog.share')}
						</a>

					</div>
					
					<div className="col-md-4">
						<SubscriptionStatus />
						<BlogFilterContainer categories={categories} />
						<FollowUs />
					</div>
				</div>
			</div>
		</div>
	)
}

export default BlogDetailContentContainer
