export type HttpMessages = { [key: number]: string };

const httpMessages: HttpMessages = {
    400: "Bad Request",
    401: "Unauthorized",
    404: "Not Found",
    403: "Forbidden",
    500: "Internal Server Error",
    502: "Bad Gateway",
    503: "Service Unavailable"
}

export class ApiError extends Error {
    constructor(public httpStatus: number, public json?: any) {
        super(`API error: ${httpMessages[httpStatus]}`);
    }
}

export async function handleJsonResponse(response: Response): Promise<any> {
    const noContentStatus = 204;
    if (response.status === noContentStatus) return null;
    let json: any;
    try {
        json = await response.json();
    }
    catch (e) {
        if (!response.ok) {
            throw new ApiError(response.status);
        } else {
            throw new Error("Incorrect response format");
        }
    }
    return json;
}

export async function handleVoidResponse(response: Response): Promise<void> {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        const json = await response.json();
        if (!response.ok) {
            throw new ApiError(response.status, json);
        }
    } else {
        if (!response.ok) {
            throw new ApiError(response.status);
        }
    }
}