import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Carousel from 'nuka-carousel'

import homebanner from '../../assets/homebannerscreen.png'

import './PopularMovieSlider.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface PopularMovieSliderProps {}



const HomeBanner: React.FC = ({}) => {
	const { t, i18n } = useTranslation(['azcinemaonline'])

        let slides = [
	       {
		   title: '',
		   buttonText: 'Смотреть все фильмы',
	           buttonLink: '/'+t('routes.movies'),
		   background: homebanner,
	        },
        ]
	return (
		<>
			<Carousel
				slidesToShow={1}
				defaultControlsConfig={{
					nextButtonText: ' ',
					nextButtonStyle: { display: 'none' },
					prevButtonText: ' ',
					prevButtonStyle: { display: 'none' },
				}}
				pauseOnHover
			>
				{slides.map((slide, index) => {
					return (
						<div
							key={index}
							className="movieTrailerSlide"
							style={{
								backgroundImage: 'url(' + slide.background + ')',
							}}
						>
							<Container fluid={true} className="px-lg-5" style={{ height: '100%' }}>
								<Row className="h-100">
									<Col className="d-flex justify-content-between align-items-end">
										<div className="movieTrailerName">{slide.title}</div>
										<div className="movieTrailerButton">
											<Link to={slide.buttonLink}>
												<div>{t('banner.watchallmovies')}</div>
											</Link>
										</div>
									</Col>
								</Row>
							</Container>
						</div>
					)
				})}
			</Carousel>
		</>
	)
}

const PopularMovieSlider: React.FC<PopularMovieSliderProps> = ({}) => {
	return (
		<>
			<div className="PopularMovieSlider">
				<HomeBanner></HomeBanner>
			</div>
		</>
	)
}

export default PopularMovieSlider
