import { ReactComponent } from '*.svg'
import React, { useRef } from 'react'
import { Col, Row } from 'reactstrap'

// @ts-ignore
import ShowMoreText from 'react-show-more-text'

import './MovieDescription.scss'
import { Movie } from '../../../store/models/movies'
import { Country } from '../../../store/models/common'
import { Genre } from '../../../store/models/genres'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useProgressiveImage } from '../../../helpers/useProgressiveImage'

interface Props {
	movie: Movie
	genres: Genre[]
	countries: Country[]
}
const MovieDescription: React.FC<Props> = ({ movie, genres, countries }) => {
	const { t } = useTranslation(['azcinemaonline'])
	const loaded = useProgressiveImage(encodeURI(movie.poster || ''))
	const showMoreButton = useRef<HTMLDivElement>(null)
	const executeOnClick = (isExpanded: any) => {
		if (showMoreButton.current !== null) {
			showMoreButton.current.classList.toggle('expanded')
		}
	}
	const router = useHistory()
	return (
		<div className="movie-description">
			<Row>
				<Col className="col-4 col-md-3 movie-poster-column">
					<div className="movie-poster">
						<div
							className="poster-content"
							style={{
								backgroundImage: `url(${loaded})`,
							}}
						></div>
					</div>
				</Col>
				<Col className="col-12 col-md-9">
					<div className="meta clearfix">
						<div className="country">
							<span>{t('moviedetail.country')}:</span>
							{countries.map((c, i) => {
								return (
									<span
										className="countryName"
										key={i}
										// onClick={() => {
										// 	router.push('/' + t('routes:movies') + '/' + c.name)
										// }}
									>
										{c.name}
									</span>
								)
							})}
						</div>
						<div className="year">
							<span>{t('moviedetail.year')}: </span>
							<span
								className="movieYear"
								onClick={() => {
									router.push('/' + t('routes.movies'), { year: movie.year })
								}}
							>
								{movie.year}
							</span>
						</div>
						<div className="time">
							{movie.duration} {t('common.minute')}
						</div>
					</div>
					<div className="description" ref={showMoreButton}>
						<ShowMoreText
							lines={3}
							more="Show more"
							less="Show less"
							anchorClass="show-more-button"
							onClick={executeOnClick}
							expanded={false}
						>
							{movie.description}
						</ShowMoreText>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default MovieDescription
