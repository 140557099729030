import React, { useEffect, useRef, useState } from 'react'
import { fetchPost } from '../../../store/rest/fetch'
import './SearchPopUp.scss'
import { SearchResponse } from '../../../store/models/common'
import { useTranslation } from 'react-i18next'
import { Row } from 'reactstrap'
import GenresList from '../../GenresList/GenresList'
import MovieListGrid from '../../MovieListGrid/MovieListGrid'
import { calculateCount } from '../../../helpers'
import useWindowSize from '../../../helpers/useWindowSize'
import config from '../../../config'
interface Props {
	close: () => void
	opened: boolean
}

export const SearchPopUp: React.FC<Props> = ({ close, opened }) => {
	const size = useWindowSize()
	const { t } = useTranslation('azcinemaonline')
	const searchInput = useRef<HTMLInputElement>(null)
	const [pause, setPause] = useState(false)
	const [takenSearch, setTakenSearch] = useState<SearchResponse>({
		movies: [],
		genres: [],
		figures: [],
	})
	useEffect(() => {
		if (searchInput && searchInput.current && opened) searchInput.current.focus()
	}, [opened])
	useEffect(() => {
		if (opened) document.documentElement.classList.add('nonScroll')
		else document.documentElement.classList.remove('nonScroll')
	}, [opened])
	useEffect(() => {
		if (searchInput.current?.value) {
		}
	}, [searchInput.current])

	const search = () => {
		setPause(true)
		setTakenSearch({
			movies: [],
			genres: [],
			figures: [],
		})

		fetchPost({ search: searchInput.current?.value }, 'search')
			.then((r) => {
				if (r.status == 'ok') {
					setTakenSearch(r.data)
				}
				setPause(false)
			})
			.catch((err) => {
				console.log(err)
				setPause(false)
			})
	}
	const searchProc = () => {
		if (searchInput && searchInput.current) {
			if (searchInput.current.value) {
				searchInput.current.classList.add('searchTrans')
				search()
			} else {
				searchInput.current.classList.remove('searchTrans')
				setTakenSearch({
					movies: [],
					genres: [],
					figures: [],
				})
			}
		}
	}
	let perCount = 0
	const width = size.width
	if (width > 1400) {
		perCount = 7
	} else if (width <= 1400 && width > 1200) {
		perCount = 7
	} else if (width <= 1200 && width > 992) {
		perCount = 6
	} else if (width <= 992 && width > 768) {
		perCount = 5
	} else if (width <= 768 && width > 576) {
		perCount = 4
	} else if (width <= 576) {
		perCount = 3
	}
	const per_page = calculateCount(size.width)
	return (
		<div className="SearchPopUp" style={{ display: opened ? 'block' : 'none' }}>
			<div className="searchHeader">
				<div className="searchTitle">{t('search.search')}</div>
				<div className="closeCross" onClick={close}>
					<i className="fas fa-times" />
				</div>
			</div>
			<input
				id="input"
				ref={searchInput}
				onChange={searchProc}
				className="searchField"
				placeholder={t('search.search')}
				type="text"
				autoComplete="off"
			/>
			{searchInput.current?.value ? (
				<>
					{pause && (
						<span className="custom_spinner_2 ml-2">
							<div className="spinner-border spinner_circle" role="status"></div>
						</span>
					)}
					<div className="genresSlider">
						{takenSearch.genres && takenSearch.genres.length > 0 && (
							<>
								<div className="SearchTitle container-fluid">{t('search.genres')}</div>
								<GenresList close={close} genres={takenSearch.genres} />
							</>
						)}
					</div>
					<div className="castSlider container">
						{false && takenSearch.figures && takenSearch.figures.length > 0 && (
							<>
								<div className="SearchTitle container-fluid">{t('search.charachters')}</div>
								<Row className="castContainer">
									{takenSearch.figures.slice(0, perCount).map((figure) => {
										return (
											<div
												className="cast col-4 col-sm-3 col-md-2 col-lg-1 col-xxl-1"
												onClick={close}
											>
												<a className="cast-box">
													<div
														className="cast-content"
														style={{
															backgroundImage:
																'url( ' +
																config.media_url +
																encodeURI(figure.preview || '') +
																')',
														}}
													></div>
												</a>

												<h3 className="cast-title">{figure.fullname}</h3>
											</div>
										)
									})}
								</Row>
							</>
						)}
					</div>
					<div className="moviesSlider">
						{takenSearch.movies && takenSearch.movies.length > 0 && (
							<>
								<div className="SearchTitle  moviesTitle container-fluid">{t('search.movies')}</div>
								<div className="movies-list">
									<MovieListGrid close={close} movies={takenSearch.movies.slice(0, per_page * 2)} />
								</div>
							</>
						)}
					</div>
					{Array.isArray(takenSearch) && takenSearch.length === 0 && (
						<h2 className="text-center my-5 font-weight-bold display-1">{t('search.notfounded')}</h2>
					)}
				</>
			) : (
				<div></div>
			)}
		</div>
	)
}
