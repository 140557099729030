import React from 'react'
import { Helmet } from 'react-helmet'
import LoadingComponent2 from '../components/LoadingComponent2/LoadingComponent2'

const Loading2: React.FC = ({}) => {
	return (
		<>
			<div className="site-loading">
				<div className="site-loading-spinner">
					<div className="spinner-border" role="status"></div>
				</div>
			</div>
			{/* <LoadingComponent2 /> */}
		</>
	)
}

export default Loading2
