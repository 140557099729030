import { GENRES_FAILURE, GENRES_REQUEST, GENRES_SUCCESS } from '../types'
import { StoreDispatch } from '../store'
import { Dispatch } from '@reduxjs/toolkit'
import { Genre, GenresFailureAction, GenresRequestAction, GenresSuccessAction } from '../models/genres'
import { ErrorArray } from '../models/common'
import { fetchGet } from '../rest/fetch'
const fetchGenresRequest = (isFetching: boolean): GenresRequestAction => {
	return {
		type: GENRES_REQUEST,
		isFetching,
	}
}

const fetchGenresReceive = (genres: Genre[]): GenresSuccessAction => {
	return {
		type: GENRES_SUCCESS,
		genres,
	}
}

const fetchGenresError = (message: ErrorArray): GenresFailureAction => {
	return {
		type: GENRES_FAILURE,
		errorMessage: message,
	}
} 

export function fetchGenres() {
	return async (dispatch: Dispatch<StoreDispatch>) => {
		dispatch(fetchGenresRequest(true))
		try {
			const response = await fetchGet('genres?per_page=404')
			if (response.status === 'ok') dispatch(fetchGenresReceive(response.data.data))
			else if (response.status === 'error') dispatch(fetchGenresError(response.data))
			dispatch(fetchGenresRequest(false))
		} catch (err) {
			dispatch(fetchGenresError(err as ErrorArray))
			dispatch(fetchGenresRequest(false))
		}
	}
}
