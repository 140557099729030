import React from 'react'

import './AboutUsComponent.scss'
import aboutus from '../../assets/aboutus.png'
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

interface AboutUsComponentProps {

}

const AboutUsComponent: React.FC = ({ }: AboutUsComponentProps) => {
    const [ t ] = useTranslation('azcinemaonline')
    return (

        <div className="about-section">
            <Container fluid={true}>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <img src={aboutus} alt="" className="about-banner"  />
                    </Col>
                </Row>
                
            </Container>
            <Container fluid={true}>
                <Row className="about-content py-md-5" style={{padding: "1rem"}}>
                    <Col className="col-md-5 col-12">
                        <h2 className="title">{t('aboutus.weare')}</h2>
                    </Col>
                    <Col className="col-md-7 col-12">
                        <p className="introduction">{t('aboutus.aboutus')}</p>
                    </Col>
                </Row>
            </Container>
        </div>

    )
}

export default AboutUsComponent
