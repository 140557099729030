import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import CollectionsComponent from '../components/CollectionsComponent/CollectionsComponent'

const Collections: React.FC = ({}) => {
	const { t } = useTranslation('azcinemaonline')
	return (
		<>
			<Helmet title={t('breadcrumbs.collections')} />
			<BreadCrumb title={t('breadcrumbs.collections')}>
				<BreadCrumbItem name={t('breadcrumbs.home')} to="/" />
				<BreadCrumbItem name={t('breadcrumbs.collections')} current={true} />
			</BreadCrumb>
			<CollectionsComponent />
		</>
	)
}

export default Collections
