import React from 'react'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import './QuestionComponent.scss'

interface QuestionComponentProps {
	question: string
	answer: string
	collapsed: boolean
	index: number
	collapse: (index: number) => void
}

const QuestionComponent: React.FC<QuestionComponentProps> = ({ question, answer, index, collapsed, collapse }) => {
	return (
		<div className="QuestionComponent">
			<div className="cardComponent" key={index}>
				<div
					className="cardHeader"
					onClick={() => {
						collapse(index)
					}}
					data-event={index}
				>
					<span className="questionTitle">{question}</span>
					{!collapsed ? <i className="fas fa-plus plus" /> : <i className="fas fa-minus minus" />}
				</div>
				<Collapse className="collapseComponent" isOpen={collapsed}>
					<div className="cardBody text-muted">
						<span className="answerContent text-muted" dangerouslySetInnerHTML={{ __html: answer }}></span>
					</div>
				</Collapse>
			</div>
		</div>
	)
}

export default QuestionComponent
