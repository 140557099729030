import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Col, Container, Row } from 'reactstrap'
import { validateEmail } from '../../helpers'
import { fetchPost } from '../../store/rest/fetch'
import { CloseButton } from '../CloseButton/CloseButton'
import './ContactForm.scss'

interface ContactFormProps {}

const ContactForm: React.FC<ContactFormProps> = ({}) => {
	const { t } = useTranslation('azcinemaonline')
	const [isFetching, setIsFetching] = useState(false)
	const [values, setValues] = useState({
		name: '',
		email: '',
		msgtitle: '',
		msg: '',
	})
	const [errors, setErrors] = useState({
		name: -1,
		email: -1,
		msgtitle: -1,
		msg: -1,
	})
	const submit = () => {
		if (isFetching) return
		if (Object.values(errors).includes(0) || Object.values(errors).includes(-1)) return
		setIsFetching(true)
		fetchPost(
			{
				fullname: values.name,
				email: values.email,
				subject: values.msgtitle,
				message: values.msg,
			},
			'contact'
		)
			.then((res) => {
				setIsFetching(false)
				if (res.status === 'error') {
					res.data.message.map((err: string) => {
						toast.error(err, { closeButton: CloseButton })
					})
				} else if (res.status === 'ok') {
					typeof res.data.message == 'string' && toast.success(res.data.message, { closeButton: CloseButton })
					setValues({
						name: '',
						email: '',
						msgtitle: '',
						msg: '',
					})
					setErrors({
						name: -1,
						email: -1,
						msgtitle: -1,
						msg: -1,
					})
				}
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
			})
	}
	return (
		<Container className="ContactForm" style={{ paddingLeft: '0', paddingRight: '0' }}>
			<Row className="bigRow">
				<Col xs={12} className="title">
					{t('contactus.title')}
				</Col>

				<Col xs={12} md={6}>
					<div
						className={
							'inputContainer ' + (errors.name === 0 ? 'wrong' : errors.name === 1 ? 'correct' : '')
						}
					>
						<div className="inputTitle">
							{t('contactus.name')} <span className="required">*</span>
						</div>
						<input
							className="input"
							placeholder={t('contactus.placename')}
							name="name"
							value={values.name}
							onChange={(e) => {
								let value: string = e.target.value
								if (value.length >= 3) {
									setErrors({ ...errors, name: 1 })
								} else {
									setErrors({ ...errors, name: 0 })
								}
								setValues({ ...values, name: value })
							}}
						/>
					</div>
				</Col>
				<Col xs={12} md={6}>
					<div
						className={
							'inputContainer ' + (errors.email === 0 ? 'wrong' : errors.email === 1 ? 'correct' : '')
						}
					>
						<div className="inputTitle">
							{t('contactus.email')} <span className="required">*</span>
						</div>
						<input
							className="input"
							placeholder={t('contactus.placeEmail')}
							name="email"
							value={values.email}
							onChange={(e) => {
								let value: string = e.target.value
								if (validateEmail(value)) {
									setErrors({ ...errors, email: 1 })
								} else {
									setErrors({ ...errors, email: 0 })
								}
								setValues({ ...values, email: value })
							}}
						/>
					</div>
				</Col>

				<Col xs={12}>
					<div
						className={
							'inputContainer ' +
							(errors.msgtitle === 0 ? 'wrong' : errors.msgtitle === 1 ? 'correct' : '')
						}
					>
						<div className="inputTitle">
							{t('contactus.msgtitle')} <span className="required">*</span>
						</div>
						<input
							className="input"
							placeholder={t('contactus.placetitle')}
							name="msgtitle"
							value={values.msgtitle}
							onChange={(e) => {
								let value: string = e.target.value
								if (value.length >= 3) {
									setErrors({ ...errors, msgtitle: 1 })
								} else {
									setErrors({ ...errors, msgtitle: 0 })
								}
								setValues({ ...values, msgtitle: value })
							}}
						/>
					</div>
				</Col>
				<Col xs={12}>
					<div
						className={
							'textareaContainer ' + (errors.msg === 0 ? 'wrong' : errors.msg === 1 ? 'correct' : '')
						}
					>
						<div className="textareaTitle">
							{t('contactus.msg')} <span className="required">*</span>
						</div>
						<textarea
							className="textarea"
							placeholder={t('contactus.placemsg')}
							name="msg"
							value={values.msg}
							onChange={(e) => {
								let value: string = e.target.value
								if (value.length >= 3) {
									setErrors({ ...errors, msg: 1 })
								} else {
									setErrors({ ...errors, msg: 0 })
								}
								setValues({ ...values, msg: value })
							}}
						/>
					</div>
				</Col>
				<Col xs={12}>
					<div className="submitContainer">
						<button className="submitButton" onClick={submit}>
							{t('contactus.btnSend')}
							{isFetching && (
								<span className="custom_spinner ml-2">
									<div className="spinner-border spinner_circle" role="status"></div>
								</span>
							)}
						</button>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default ContactForm
