import { combineReducers } from '@reduxjs/toolkit'
import { genres } from './genres.reducer'
import { settings } from './siteSettings.reducer'
import { moviesReducer } from './movies.reducer'
import { auth } from './auth.reducer'
import { profile } from './profile.reducer'

const reducers = combineReducers({
	genres,
	settings,
	movies: moviesReducer,
	auth,
	profile,
})

export default reducers
export type AppState = ReturnType<typeof reducers>
