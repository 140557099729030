import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Col, Container, Row } from "reactstrap";
import { fetchPost } from "../../store/rest/fetch";
import "./ResetPassComponent.scss";
import { toast } from "react-toastify";
import { CloseButton } from "../CloseButton/CloseButton";
import { ErrorArray } from "../../store/models/common";
import { useDispatch } from "react-redux";
import { SETTINGS } from "../../store/types";

interface ResetPassComponentProps {
  email: string;
  token: string;
}

const ResetPassComponent: React.FC<ResetPassComponentProps> = ({
  email,
  token,
}) => {
  const { t } = useTranslation("azcinemaonline");
  const router = useHistory();
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState<ErrorArray>({});
  const [isFetching, setIsFetching] = useState(false);

  const chngPass = () => {
    if (isFetching) return;
    setIsFetching(true);
    newPassword &&
      fetchPost(
        {
          password: newPassword,
          password_confirmation: confirmNewPassword,
          email: email,
          token: token,
        },
        "reset-password"
      )
        .then((res) => {
          setIsFetching(false);
          if (res.status === "error") {
            setErrorMessages(res.data.message);
          } else if (res.status === "ok") {
            typeof res.data.message == "string"
              ? toast.success(res.data.message, { closeButton: CloseButton })
              : toast.success(t("account.successfullyСhanged"), {
                  closeButton: CloseButton,
                });
            router.push("/" + t("routes.account"));

            dispatch({
              type: SETTINGS.LOGIN_POPUP,
              payload: true,
            });
            setNewPassword("");
            setConfirmNewPassword("");
          }
        })
        .catch((err) => {
          setIsFetching(false);
          console.log(err);
        });
  };

  return (
    <Container fluid={true} className="SettingsPage">
      {token != null && token ? (
        <Row className="bigRow ">
          <Row>
            <div
              onClick={() => {
                router.push("/" + t("routes.account"));
              }}
              className="backToAccaunt "
            >
              <i className="fas fa-chevron-left"></i>
              {t("account.backButton")}
            </div>
          </Row>
          <Col xs={12} className="title">
            {t("account.change")}
          </Col>

          <Col md={4}>
            <div className={"inputContainer "}>
              <div className="inputTitle">Your Email</div>
              <input
                className="input notActive"
                type="email"
                name="email"
                value={email}
                readOnly
              />
            </div>
          </Col>
          <Col md={4}>
            <div className={"inputContainer "}>
              <div className="inputTitle">
                {t("account.newpass")} <span className="required">*</span>
              </div>
              <input
                className={"input "}
                placeholder={t("account.placeconfirm")}
                type="password"
                value={newPassword}
                onChange={(e) => {
                  let value: string = e.target.value;
                  setNewPassword(value);
                }}
              />
              {errorMessages &&
                errorMessages.password &&
                errorMessages.password.map((e, i) => {
                  return <small className="error text-danger">{e}</small>;
                })}
            </div>
          </Col>
          <Col md={4}>
            <div className={"inputContainer "}>
              <div className="inputTitle">
                {t("account.confirmpass")} <span className="required">*</span>
              </div>
              <input
                className={"input "}
                placeholder={t("account.placeconfirm")}
                type="password"
                value={confirmNewPassword}
                onChange={(e) => {
                  let value: string = e.target.value;
                  setConfirmNewPassword(value);
                }}
              />
              {errorMessages &&
                errorMessages.confirm_password &&
                errorMessages.confirm_password.map((e, i) => {
                  return <small className="error text-danger">{e}</small>;
                })}
            </div>
          </Col>
          <Col md={{ size: 3, offset: 9 }}>
            <div className="submitContainer">
              <button
                className="submitButton"
                onClick={() => {
                  chngPass();
                }}
              >
                {t("account.submit")}
                {isFetching && (
                  <span className="custom_spinner ml-2">
                    <div
                      className="spinner-border spinner_circle"
                      role="status"
                    ></div>
                  </span>
                )}
              </button>
            </div>
          </Col>
        </Row>
      ) : (
        <div className="ResetPass">
          <Row>
            <div
              onClick={() => {
                router.push("/" + t("routes.account"));
              }}
              className="backToAccaunt "
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-chevron-left"></i>
              {t("account.backButton")}
            </div>
          </Row>
          <div className="timeisover mt-3 mb-5">
            Time to reset the password is over!
          </div>
        </div>
      )}
    </Container>
  );
};

export default ResetPassComponent;
