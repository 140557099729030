import React from 'react'
import { Helmet } from 'react-helmet'
import { Col, Container } from 'reactstrap'
import { BreadCrumb, BreadCrumbItem } from '../BreadCrumb/BreadCrumb'
import SponsorsContainer from '../SponsorsContainer/SponsorsContainer'
import SerialDescription from './SerialDescription/SerialDescription'
import SerialHeader from './SerialHeader/SerialHeader'
import SerialInfo from './SerialInfo/SerialInfo'
import SerialTrailer from './SerialTrailer/SerialTrailer'

const SerialDetail: React.FC = () => {
  return (
    <div className='SerialDetail'>
        <Helmet title={"Serial Detail"} />
        <BreadCrumb title={"Serial Detail"}>
            <BreadCrumbItem name={"Home"} to="/" />
            <BreadCrumbItem name={"Serials"} to="/serials" />
            <BreadCrumbItem name={"Serial Detail"} current={true} />
        </BreadCrumb>
        <Container fluid={true} className="d-flex flex-wrap">
          <Col xs='12'>
            <SerialHeader/>
          </Col>
          <Col xs='8' className='p-0'>
            <SerialTrailer/>
            <SerialDescription/>
          </Col>
          <Col xs='4'>
            <SerialInfo/>
          </Col>
        </Container>
        <SponsorsContainer/>
    </div>
  )
}

export default SerialDetail
