import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Col, Container } from 'reactstrap'
import { validateEmail } from '../../helpers'
import { fetchGet, fetchPost } from '../../store/rest/fetch'
import { CloseButton } from '../CloseButton/CloseButton'
import './Subscription.scss'

interface SubscriptionStatusProps {}

const SubscriptionStatus: React.FC<SubscriptionStatusProps> = () => {
	const { t } = useTranslation('azcinemaonline')
	const [isFetching, setIsFetching] = useState(false)
	const [email, setEmail] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(-1)
	const submit = () => {
		if (isFetching || emailIsValid == -1) return
		setIsFetching(true)
		fetchPost({ email }, 'subscribe')
			.then((res) => {
				setIsFetching(false)
				if (res.status === 'error') {
					res.data.message.map((err: string) => {
						toast.error(err, { closeButton: CloseButton })
					})
				} else if (res.status === 'ok') {
					typeof res.data.message == 'string' && toast.success(res.data.message, { closeButton: CloseButton })
					setEmail('')
					setEmailIsValid(-1)
				}
			})
			.catch((err) => {
				console.log(err)
				setIsFetching(false)
			})
	}
	return (
		<Container className="SubscriptionStatus">
			<Col className="title" xs={12}>
				{t('blog.subscription')}
			</Col>

			<Col className="inputContainer" xs={12}>
				<input
					className={
						'emailInput ' +
						(emailIsValid >= 0 ? (emailIsValid === 0 && 'inValid') || (emailIsValid === 1 && 'valid') : '')
					}
					placeholder={t('login.enteremail')}
					name="email"
					value={email}
					onChange={(e) => {
						validateEmail(e.target.value) ? setEmailIsValid(1) : setEmailIsValid(0)
						setEmail(e.target.value)
					}}
				></input>
			</Col>

			<Col className="buttonContainer" xs={12}>
				<button className="confirmButton" onClick={submit}>
					{t('blog.subscribe')}
					{isFetching && (
						<span className="custom_spinner ml-2">
							<div className="spinner-border spinner_circle" role="status"></div>
						</span>
					)}
				</button>
			</Col>
		</Container>
	)
}

export default SubscriptionStatus
