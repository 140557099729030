import React, { useEffect, useState } from 'react'
import { Col, Progress } from 'reactstrap'
import '../CrowdfundingComponent.scss'
import previewPhoto from '../../../assets/photo.jpg'
import { Link } from 'react-router-dom'
import { useProgressiveImage } from '../../../helpers/useProgressiveImage'
import { useTranslation } from 'react-i18next'

interface CrowdfundingCardProps {
  photo: string
  title: string
  short: string
  id: number
  type: string,
  total: number,
  budget: number
}

const CrowdfundingCard: React.FC<CrowdfundingCardProps> = ({
  photo,
  title,
  short,
  id,
  type,
  total,
  budget
}) => {
  const { t } = useTranslation(['azcinemaonline'])
  const [ percent, setPercent ] = useState(0)
  const [ totalStr, setTotalStr ] = useState('')

  useEffect(()=> {
    setPercent((total / budget) * 100)
    setTotalStr(`${total}`)
  })

  const posterCover = useProgressiveImage(encodeURI(photo || ''))

  return (
    <Col className="p-2" xs="12" sm="6" md="4">
      <Link
        to={'/' + t('routes.crowdfundingdetail') + '/' + id}
        className="projectContainer"
      >
        <div className="projectContainer project-film">
          {photo != '' ? (
            <img src={posterCover} alt="" className="projectPreview" />
          ) : (
            <img src={previewPhoto} alt="" className="projectPreview" />
          )}

          <div className="orange-hover text-center">Поддержать проект +</div>
          <div className="projectInfoContainer w-100">
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="projectTitle">{title}</div>
              <div className="projectCategory film">{type}</div>
            </div>
            <div className="projectTinyDescription mb-2">{short}</div>
            <div className="progresbarConrainer d-flex w-100 flex-wrap">
              <Progress className="w-100 mb-2" multi>
                <Progress bar value={percent} />
              </Progress>
              <Col xs="6 d-flex justify-content-start flex-wrap progressContainer pl-0 ">
                <div className="progressPercent w-100">{percent.toFixed(2)}%</div>
                <div className="progressTitle w-100">{t("crowdfunding.gathering")}</div>
              </Col>
              <Col xs="6 d-flex justify-content-end flex-wrap gatheredContainer pr-0">
                <div className="gatheredMoney w-100 d-flex justify-content-end">
                  {parseInt(totalStr)} ₼
                </div>
                <div className="gatheredTitle w-100 d-flex justify-content-end">
                  {t("crowdfunding.gathered")}
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Link>
    </Col>
  )
}

export default CrowdfundingCard
