import React, { useState, useEffect } from 'react'
import {
  NavLink,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom'
import { Collapse, Navbar, Container, Nav, NavItem } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { ReactComponent as LogoLight } from '../../assets/logo_light1.svg'
import { ReactComponent as Logo } from '../../assets/logo1.svg'
import { ReactComponent as Account } from '../../assets/login.svg'
import { ReactComponent as Search } from '../../assets/search.svg'
import { useDispatch, useSelector } from 'react-redux'
import { SETTINGS } from '../../store/types'
import { SearchPopUp } from './SearchPopUp/SearchPopUp'
import { CSSTransition } from 'react-transition-group'
import { AppState } from '../../store/reducers'
import './Header.scss'
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher'

interface HeaderProps extends RouteComponentProps {}

const Header: React.FC<HeaderProps> = ({ location }) => {
  const { t, i18n } = useTranslation(['azcinemaonline'])
  const router = useHistory()
  const [darkTheme, setDarkTheme] = useState(
    localStorage.getItem('isDarkTheme') ? true : false,
  )
  const setTheme = () => {
    setDarkTheme(!darkTheme)
    if (!darkTheme) localStorage.setItem('isDarkTheme', 'dark')
    else localStorage.removeItem('isDarkTheme')
  }
  useEffect(() => {
    if (darkTheme) document.getElementById('root')?.classList.add('dark-theme')
    else document.getElementById('root')?.classList.remove('dark-theme')
  }, [darkTheme])

  // useEffect(() => {
  // 	if (darkTheme) document.getElementById('root')?.classList.add('dark-theme')
  // 	else document.getElementById('root')?.classList.remove('dark-theme')
  // }, [location])
  const isAuthenticated = useSelector(
    (state: AppState) => state.auth.isAuthenticated,
  )
  const dispatch = useDispatch()
  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    if (collapsed) document.getElementById('root')?.classList.add('nonScroll')
    else document.getElementById('root')?.classList.remove('nonScroll')
  }, [collapsed])

  const toggle = () => {
    setCollapsed(!collapsed)
  }
  const [searchPopUp, setSearchPopUp] = useState(false)
  return (
    <>
      <div id="header">
        <CSSTransition
          in={searchPopUp}
          timeout={300}
          classNames="search"
          onEnter={() => setSearchPopUp(true)}
          onExited={() => setSearchPopUp(false)}
        >
          <SearchPopUp
            close={() => {
              setSearchPopUp(false)
            }}
            opened={searchPopUp}
          />
        </CSSTransition>
        <Container fluid={true}>
          <Navbar expand="md">
            <NavLink to="/" className="logo">
              {darkTheme ? <LogoLight /> : <Logo />}
            </NavLink>
            <div className="navLinksMobile">
              <Nav className="mx-auto navNavLinksMobile" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link navLinkfirst"
                    to={'/' + t('routes.movies')}
                  >
                    {t('header.Movies')}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
									<NavLink className="nav-link navLink" to={'/' + t('routes.tv')}>
										{t('TV')}
									</NavLink>
								</NavItem> */}
                <NavItem>
                  <NavLink
                    className="nav-link navLink"
                    to={'/' + t('routes.blog')}
                  >
                    {t('header.blog')}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
									<NavLink className="nav-link navLink" to={'/' + t('routes:collections')}>
										{t('header:collections')}
									</NavLink>
								</NavItem> */}
                <NavItem>
                  <NavLink
                    className="nav-link navLink"
                    to={'/' + t('routes.aboutus')}
                  >
                    {t('header.about')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link navLink"
                    to={'/' + t('routes.crowdfunding')}
                  >
                    {t('header.crowdfunding')}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            <div className="right-nav-mobile">
              <div className="theme-switcher custom-control custom-switch">
                <input
                  type="checkbox"
                  id="theme-switcher"
                  className="custom-control-input"
                  onChange={setTheme}
                  checked={darkTheme ? true : false}
                />
                <label
                  className="custom-control-label"
                  htmlFor="theme-switcher"
                ></label>
              </div>
              <LanguageSwitcher />
            </div>

            <input
              type="checkbox"
              id="navbar-toggler"
              className="navbar-toggler d-none"
              onChange={() => {
                toggle()
              }}
              checked={collapsed}
            ></input>
            <label
              className="toggle-btn toggle-btn__cross"
              htmlFor="navbar-toggler"
            >
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </label>

            <Collapse
              navbar
              isOpen={collapsed}
              style={{ overflow: collapsed ? 'auto' : '' }}
            >
              <Nav className="mx-auto" navbar>
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={'/' + t('routes.movies')}
                    onClick={() => {
                      setCollapsed(false)
                    }}
                  >
                    {t('header.Movies')}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
									<NavLink className="nav-link" to={'/' + t('routes:tv')}>
										{t('TV')}
									</NavLink>
								</NavItem> */}
                <NavItem>
                  <NavLink
                    className="nav-link"
                    to={'/' + t('routes.blog')}
                    onClick={() => {
                      setCollapsed(false)
                    }}
                  >
                    {t('header.blog')}
                  </NavLink>
                </NavItem>
                {/* <NavItem>
									<NavLink className="nav-link" to={'/' + t('routes:collections')}>
										{t('header:collections')}
									</NavLink>
								</NavItem> */}
                <NavItem>
                  <NavLink
                    className="nav-link navLink"
                    to={'/' + t('routes.aboutus')}
                    onClick={() => {
                      setCollapsed(false)
                    }}
                  >
                    {t('header.about')}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className="nav-link navLink"
                    to={'/' + t('routes.crowdfunding')}
                    onClick={() => {
                      setCollapsed(false)
                    }}
                  >
                    {t('header.crowdfunding')}
                  </NavLink>
                </NavItem>
                <li className="breaker"></li>
                <NavItem className="profil">
                  <NavLink
                    className="navlink account"
                    to={'/' + t('routes.account')}
                    onClick={() => {
                      setCollapsed(!collapsed)
                    }}
                  >
                    {t('header.profile')}
                  </NavLink>
                </NavItem>
                <NavItem className="">
                  <a
                    className="nav-link searchLink"
                    onClick={() => {
                      setSearchPopUp(true)
                      toggle()
                    }}
                  >
                    {t('header.search')}
                  </a>
                </NavItem>
              </Nav>
              <div className="subscription mr-5">
                <div
                  className="d-flex justify-content-center p-2 text-white font-weight-bold"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    router.push(
                      '/' +
                        t('routes.account') +
                        '/' +
                        t('routes.subscription'),
                    )
                    // if (isAuthenticated) {
                    // 	dispatch({
                    // 		type: SETTINGS.SUBSCRIPTION_POPUP,
                    // 		payload: true,
                    // 	})
                    // } else {
                    // 	dispatch({
                    // 		type: SETTINGS.LOGIN_POPUP,
                    // 		payload: true,
                    // 	})
                    // }
                  }}
                >
                  $ {t('header.subscription')}
                </div>
              </div>
              <div className="right-nav">
                <div
                  className="search"
                  onClick={() => {
                    setSearchPopUp(true)
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Search className="searchIcon" />
                </div>
                <NavLink
                  to={'/' + t('routes.account')}
                  className="account mx-2"
                >
                  <Account />
                </NavLink>
                <div className="theme-switcher custom-control custom-switch">
                  <input
                    type="checkbox"
                    id="theme-switcher1"
                    className="custom-control-input"
                    onChange={setTheme}
                    checked={darkTheme ? true : false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="theme-switcher1"
                  ></label>
                </div>
                <LanguageSwitcher />
              </div>
            </Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  )
}

export default withRouter(Header)
