import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { Genre } from '../../../store/models/genres'
import { Movie } from '../../../store/models/movies'
import './MovieHeader.scss'

interface Props {
	movie: Movie
	genres: Genre[]
}

const MovieHeader: React.FC<Props> = ({ movie, genres }) => {
	return (
		<div className="movie-header">
			<Row>
				<Col className="col-9">
					<h1 className="title">
						{movie.name}
						{/* <span className="original">({movie.name})</span> */}
					</h1>
				</Col>
				<Col className="col-3 text-right">
					<span className="age">{movie.ageLimit}+</span>
				</Col>
			</Row>

			<Row className="pb-3">
				<Col md={8} xs={12}>
					<ul className="genre-list">
						{genres.map((genre, index) => {
							return (
								<li className="genre-item" key={index}>
									<Link to={'/movies/' + genre.slug}>{genre.name}</Link>
								</li>
							)
						})}
					</ul>
				</Col>
			</Row>
		</div>
	)
}

export default MovieHeader
