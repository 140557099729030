import React, { useEffect, useState } from 'react'
import { Container, Row } from 'reactstrap'

import { useTranslation } from 'react-i18next'

import './GenresList.scss'
import 'swiper/swiper.scss'

import CameraSVG from '../../assets/genres/Camera'
import ClapperboardSVG from '../../assets/genres/Clapperboard'
import GlassesSVG from '../../assets/genres/Glasses'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Genre } from '../../store/models/genres'
import { Link } from 'react-router-dom'
import config from '../../config'

interface GenresList {
	close?: () => void
	genres: Genre[]
}

const GenresList: React.FC<GenresList> = ({ genres, close }) => {
	const { t, i18n } = useTranslation(['azcinemaonline'])
	const [swiper, setSwiper] = useState<any>(null)
	useEffect(() => {
		swiper && swiper.update()
		setTimeout(() => {
			swiper && swiper.update()
		}, 500)
	}, [genres, genres.length, swiper])
	return (
		<>
			<div className="GenresList">
				<Container fluid={true} className="px-lg-5">
					<Swiper {...config.gadimlieSettings} onSwiper={(swiper) => setSwiper(swiper)}>
						{genres.map((category, index) => {
							return (
								<SwiperSlide key={index} onClick={close}>
									<div className="genre-card">
										<Link
											to={'/' + t('routes.movies') + '/' + category.slug}
											className="d-flex justify-content-center align-items-center card"
										>
											<div dangerouslySetInnerHTML={{ __html: category.icon }}></div>
											<div className="genre-slide-item">{category.name}</div>
										</Link>
									</div>
								</SwiperSlide>
							)
						})}
					</Swiper>
				</Container>
			</div>
		</>
	)
}

export default GenresList
