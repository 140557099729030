import React, { useState } from 'react'
import { Col, Nav, NavItem, NavLink, Progress, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import poster from '../../../assets/education.jpeg'
import { ReactComponent as Play } from '../../../assets/play-button.svg'

interface SerialInfoProps {

}

const SerialInfo: React.FC<SerialInfoProps> = ({}) => {
    // State for current active Tab
    const [currentActiveTab, setCurrentActiveTab] = useState('1');
  
    // Toggle active state for Tab
    const toggle = (tab: React.SetStateAction<string>) => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }

    const [ hover, setHover ] = useState(false);


    return (
    <div className='SerialInfo'>
        <div className="info-container">
            <div className="season-container">
                <Nav tabs className='season-tabs w-100'>
                    <NavItem className='season-links'>
                        <NavLink
                            className={classnames({
                                active:
                                    currentActiveTab === '1'
                            })}
                            onClick={() => { toggle('1'); }}
                        >
                            Season 1
                        </NavLink>
                    </NavItem>
                    <NavItem className='season-links'>
                        <NavLink
                            className={classnames({
                                active:
                                    currentActiveTab === '2'
                            })}
                            onClick={() => { toggle('2'); }}
                        >
                            Season 2
                        </NavLink>
                    </NavItem>
                    <NavItem className='season-links'>
                        <NavLink
                            className={classnames({
                                active:
                                    currentActiveTab === '3'
                            })}
                            onClick={() => { toggle('3'); }}
                        >
                            Season 3
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={currentActiveTab} className='w-100 pt-3' style={{ height: "500px" ,overflow: "auto"}}>
                    <TabPane tabId="1">
                        <Row>
                            <Col sm="12">
                                <div className="serial-container">
                                    <div className="poster col-4 p-0" style={{backgroundImage: `url(${poster})`}}>
                                        {/* <div className="w-100 orange-bkg">
                                            <Play/>
                                        </div> */}
                                    </div>
                                    <div className="col-8 seria-info">
                                        <div className="seria-title">
                                            Seria Title
                                        </div>
                                        <div className="seria-progression">
                                            <Progress className='w-100 mb-3' multi>
                                                <Progress bar value="37" />
                                            </Progress>
                                        </div>
                                        <div className="serial-duration text-muted">
                                            34min
                                        </div>
                                    </div>
                                </div>
                                <div className="serial-container">
                                    <div className="poster col-4 p-0" style={{backgroundImage: `url(${poster})`}}>
                                        {/* <div className="w-100 orange-bkg">
                                            <Play/>
                                        </div> */}
                                    </div>
                                    <div className="col-8 seria-info">
                                        <div className="seria-title">
                                            Seria Title
                                        </div>
                                        <div className="seria-progression">
                                        <Progress className='w-100 mb-3' multi>
                                            <Progress bar value="37" />
                                        </Progress>
                                        </div>
                                        <div className="serial-duration text-muted">
                                            34min
                                        </div>
                                    </div>
                                </div>
                                <div className="serial-container">
                                    <div className="poster col-4 p-0" style={{backgroundImage: `url(${poster})`}}>
                                        {/* <div className="w-100 orange-bkg">
                                            <Play/>
                                        </div> */}
                                    </div>
                                    <div className="col-8 seria-info">
                                        <div className="seria-title">
                                            Seria Title
                                        </div>
                                        <div className="seria-progression">
                                        <Progress className='w-100 mb-3' multi>
                                            <Progress bar value="37" />
                                        </Progress>
                                        </div>
                                        <div className="serial-duration text-muted">
                                            34min
                                        </div>
                                    </div>
                                </div>
                                <div className="serial-container">
                                    <div className="poster col-4 p-0" style={{backgroundImage: `url(${poster})`}}>
                                        {/* <div className="w-100 orange-bkg">
                                            <Play/>
                                        </div> */}
                                    </div>
                                    <div className="col-8 seria-info">
                                        <div className="seria-title">
                                            Seria Title
                                        </div>
                                        <div className="seria-progression">
                                        <Progress className='w-100 mb-3' multi>
                                            <Progress bar value="37" />
                                        </Progress>
                                        </div>
                                        <div className="serial-duration text-muted">
                                            34min
                                        </div>
                                    </div>
                                </div>
                                <div className="serial-container">
                                    <div className="poster col-4 p-0" style={{backgroundImage: `url(${poster})`}}>
                                        {/* <div className="w-100 orange-bkg">
                                            <Play/>
                                        </div> */}
                                    </div>
                                    <div className="col-8 seria-info">
                                        <div className="seria-title">
                                            Seria Title
                                        </div>
                                        <div className="seria-progression">
                                        <Progress className='w-100 mb-3' multi>
                                            <Progress bar value="37" />
                                        </Progress>
                                        </div>
                                        <div className="serial-duration text-muted">
                                            34min
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col sm="12">
                                <h5>Sample Tab 2 Content</h5>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="3">
                        <Row>
                            <Col sm="12">
                                <h5>Sample Tab 3 Content</h5>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    </div>
  )
}

export default SerialInfo
