import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Col } from 'reactstrap'
import { useProgressiveImage } from '../../helpers/useProgressiveImage'
import { Post } from '../../store/models/common'

interface BlogContainerCardProps {
	post: Post
}

export const BlogContainerCard: React.FC<BlogContainerCardProps> = ({ post }) => {
	const loaded = useProgressiveImage(encodeURI(post.preview || ''))
	const history = useHistory()
	const { t, i18n } = useTranslation(['azcinemaonline'])

	return (
		<Col className="blog-block col-by col col-sm-6 col-md-4">
			<div
				className="blog-box"
				// onClick={() => {
				// 	history.push('/' + t('routes:blog') + '/' + post.id)
				// }}
			>
				<Link
					to={'/' + t('routes.blog') + '/' + post.id}
					// href="#"
					className="blog-content"
					style={{
						backgroundImage: `url(${loaded})`,
					}}
				>
					<h3 className="title">{post.title}</h3>
				</Link>
			</div>
		</Col>
	)
}
