import { ProfileAction, ProfileState } from '../models/profile'
import { PROFILE_FAILURE, PROFILE_REQUEST, PROFILE_SUCCESS } from '../types'

export const profile = (
	state: ProfileState = {
		errorMessage: null,
		isFetching: false,
		profile: null,
	},
	action: ProfileAction
): ProfileState => {
	switch (action.type) {
		case PROFILE_REQUEST:
			return { ...state, isFetching: action.isFetching }
		case PROFILE_FAILURE:
			return { ...state, errorMessage: action.errorMessage }
		case PROFILE_SUCCESS:
			return { ...state, profile: action.profile }
		default:
			return state
	}
}
