import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Container, Row } from 'reactstrap'
import { BreadCrumb, BreadCrumbItem } from '../components/BreadCrumb/BreadCrumb'
import { PageInfoHeader } from '../components/PageInfoHeader/PageInfoHeader'
import QuestionsContainer from '../components/QuestionsContainer/QuestionsContainer'
import SponsorsContainer from '../components/SponsorsContainer/SponsorsContainer'
import StillQuestionsContainer from '../components/StillQuestionsContainer/StillQuestionsContainer'
import i18n from '../i18n'

const Faq: React.FC = () => {
	const lang = i18n.language
	const title = lang === 'en' ? "Frequently Asked Questions" : lang==='ru' ? "FAQ" : "Sual-cavab" 
	const homeTitle = lang === 'en' ? "Home" : lang==='ru' ? "Главная" : "Ana səhifə"
	return (
		<>
			<Helmet title={title} />
			{/* <PageInfoHeader text="FAQ Page" bgImg='https://picsum.photos/1600/500' /> */}
			<BreadCrumb title={title}>
				<BreadCrumbItem name={homeTitle} to="/" />
				<BreadCrumbItem name={title} current={true} />
			</BreadCrumb>
			<Container>
				<Row className="justify-content-center">
					<QuestionsContainer />
				</Row>
			</Container>
			<SponsorsContainer />
		</>
	)
}

export default Faq
