import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import BigMovieCard from '../BigMovieCard/BigMovieCard'

import { useTranslation } from 'react-i18next'

import './CategoryContainer.scss'
import 'swiper/swiper.scss'

import { ReactComponent as Arrow } from '../../assets/right-arrow-of-straight-thin-line.svg'

import { Swiper, SwiperSlide } from 'swiper/react'
import { fetchGet } from '../../store/rest/fetch'
import { Genre } from '../../store/models/genres'
import { Link } from 'react-router-dom'
import config from '../../config'

interface CategoryContainerProps {
	genre: Genre
}

 
const CategoryContainer: React.FC<CategoryContainerProps> = ({ genre }) => {
	const [movies, setMovies] = useState([])
	const [swiper, setSwiper] = useState<any>(null)
	const { t } = useTranslation('azcinemaonline')

	useEffect(() => {
		if (genre)
			fetchGet('movies?genres[]=' + genre.id + '&per_page=20').then((r) => {
				r.status === 'ok' && setMovies(r.data.data)
			})
	}, [])
	useEffect(() => {
		swiper && swiper.update()
		setTimeout(() => {
			swiper && swiper.update()
		}, 500)
	}, [movies, movies.length, swiper])

	if (movies.length > 0)
		return (
			<div className="CategoryContainer">
				<Container fluid={true} className="px-lg-5">
					<Row className="align-items-center">
						<Col className="d-flex justify-content-between pb-2 title" md={{ size: 12 }}>
							<h2>{genre.name}</h2>
							<div className="arrowLink ml-auto d-none d-sm-flex align-items-center">
								<Link to={'/' + t('routes.movies') + '/' + genre.slug} className="view">
									{t('home.view')}
								</Link>
								<Arrow className="straightArrow" />
							</div>
						</Col>
					</Row>
					<Swiper {...config.gadimlieSettings} onSwiper={(swiper) => setSwiper(swiper)}>
						{movies.map((movie, index) => {
							return (
								<SwiperSlide key={index}>
									<BigMovieCard movie={movie} />
								</SwiperSlide>
							)
						})}
					</Swiper>
				</Container>
			</div>
		)
	else return <div></div>
}

export default CategoryContainer
